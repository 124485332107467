import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  border: 1px solid black;
  border-radius: 4px;
  margin-bottom: 20px;
`

export const WrapperTitle = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex: 1;
`

export const Title = styled.h5`
  display: flex;
  justify-content: space-between;
  padding-right: 25px;

  svg {
    cursor: pointer;
    font-size: 20px;
    margin-left: 20px;
    z-index: 100;
  }

  ${() => css`
    color: #424242;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.2;
    padding-bottom: 10px;
    padding-left: 16px;
    padding-top: 10px;
    position: relative;
    z-index: 10;
  `}
`

export const Content = styled.div`
  ${({ active }) => css`
    display: ${active ? 'block' : 'none'};
    padding-bottom: 16px;
    padding-left: 24px;

    padding-top: 16px;
  `}
`

export const Action = styled.div`
  align-items: center;
  display: flex;
`
