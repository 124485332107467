import React from 'react'
import { Route } from 'react-router-dom'
import {
  MainPanelPage,
  BenefitsPage,
  OffersPage,
  PartnershipPage,
  QuestionsPage,
  CentralPage,
  ThemePage,
  ProjectEditorPage,
  ListPage,
  LoginPage
} from 'pages'
import { PrivateTemplate, LogOffTemplate } from 'components/templates'
import { isAuthenticated } from 'services/auth'

const Routes = () => {
  return (
    <>
      {(isAuthenticated() && (
        <PrivateTemplate>
          <Route exact path="/" component={ListPage} />
          <Route
            exact
            path="/project-editor/:tab/:id"
            component={ProjectEditorPage}
          />
          <Route
            exact
            path="/project-editor/:tab"
            component={ProjectEditorPage}
          />
          <Route exact path="/main-panel" component={MainPanelPage} />
          <Route exact path="/benefits" component={BenefitsPage} />
          <Route exact path="/offers" component={OffersPage} />
          <Route exact path="/partnership" component={PartnershipPage} />
          <Route exact path="/questions" component={QuestionsPage} />
          <Route exact path="/central" component={CentralPage} />
          <Route exact path="/theme" component={ThemePage} />
        </PrivateTemplate>
      )) || (
        <LogOffTemplate>
          <Route exact path="/" component={LoginPage} />
          <Route exact path="/entrar" component={LoginPage} />
        </LogOffTemplate>
      )}
    </>
  )
}

export default Routes
