import React from 'react'
import PropTypes from 'prop-types'
import * as S from './styles'

export const Typography = ({
  tag,
  children,
  spacing,
  variant,
  align,
  ...props
}) => (
  <S.Wrapper
    variant={variant}
    tag={tag}
    align={align}
    spacing={spacing}
    {...props}
  >
    {children}
  </S.Wrapper>
)

Typography.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(['primary', 'secondary']),
  tag: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'text',
    'support',
    'disclaimer',
    'label',
    'subtitle',
    'default',
    'badge',
    'span'
  ]),
  align: PropTypes.oneOf(['start', 'end', 'center', 'inherit']),
  spacing: PropTypes.oneOf([
    '',
    'min',
    'xs',
    'smx',
    'sm',
    'sml',
    'base',
    'mdx',
    'md',
    'mdl',
    'lgx',
    'lg',
    'lgl',
    'max'
  ])
}

Typography.defaultProps = {
  tag: 'text',
  variant: 'primary',
  align: 'start',
  spacing: ''
}
