import styled from 'styled-components'

export const Label = styled.span`
  color: #2a3037;
  font-size: 15px;
  font-weight: 700;
`

export const Value = styled.span`
  color: #424242;
  font-size: 15px;
  font-weight: normal;
  margin-left: 20px;
`
