import { LOGOUT, POST_SIGNIN_SUCCESS } from 'redux/constants/Auth/SignIn'

const initialState = {
  payload: false
}

const Logout = (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT:
      return {
        payload: action.payload === 'logout' ? true : false
      }
    case POST_SIGNIN_SUCCESS:
      return {
        payload: false
      }
    default:
      return state
  }
}

export default Logout
