import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { uniqueId, forEach } from 'lodash'
import { useFormik } from 'formik'
import { useSelector } from 'react-redux'
import {
  Typography,
  Button,
  TextInput,
  ColorPicker,
  TextareaInput,
  CardContainer,
  Grid,
  Col,
  Row,
  Image
} from 'components/common'

import MinusIcon from 'assets/icons/minus.svg'
import PlusIcon from 'assets/icons/plus.svg'
import { useRegistration } from 'hooks'

export const QuestionsPage = ({ onPreviousPage, onNextPage }) => {
  const registration = useRegistration()

  const {
    partnerData: { payload },
    data: { payload: dataFormGlobalStore }
  } = useSelector(({ registration }) => registration)

  const [QuestionsList , setQuestionsList] = useState(
  [
    {
      id: uniqueId(),
      pergunta: '',
      resposta: ''
    }
  ])

  useEffect(() => {
      formik.setFieldValue('questPrimaryColor', dataFormGlobalStore.questPrimaryColor || payload.questPrimaryColor)

      const newQuestionsList = []

      forEach(dataFormGlobalStore.faqItens || payload.faqItens || QuestionsList, (item) => {
        newQuestionsList.push({
          id: uniqueId(),
          pergunta: `${item.pergunta}`,
          resposta: `${item.resposta}`
        })
      })

      setQuestionsList(newQuestionsList)
    
  }, [payload, dataFormGlobalStore])

  const formik = useFormik({
    initialValues: {
      questPrimaryColor: dataFormGlobalStore.questPrimaryColor || ''
    },
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: () => { }
  })

  const setItem = (field, value, id) => {
    var item     = QuestionsList.find(x => x.id == id);
    var restItem = QuestionsList.filter(x => x.id != id);

    item[field] = value;

    setQuestionsList(restItem ? [...restItem, {...item}] : [{...item}])
  }

  const handleAddQuestion = () => {
    const newQuestionList = [...QuestionsList]

    newQuestionList.push({
      id: uniqueId(),
      pergunta: "",
      resposta: ""
    })
    
    setQuestionsList(newQuestionList)
  }

  const handleRemoveQuestion = (_id) => {
    const RestQuestion = QuestionsList.filter(x => x.id != _id);
    setQuestionsList(RestQuestion);
  }

  const handleClickNext = () => {
    var faqItens =  QuestionsList.map(x => ({
      pergunta: x.pergunta,
      resposta: x.resposta
    }))

    registration.setManyProp({ faqItens }, formik);

    onNextPage()
  }

  const getLastItemQuestion = (id) => {
    return (QuestionsList.slice(-1)[0].id === id)
  }

  const IsDisableButton = () => {
    var itemEmpty = (QuestionsList.filter(x => x.pergunta == "" || x.resposta == ""))

    return (itemEmpty.length > 0);
  }

  const InputQuestionComponent = (item) => (
    <React.Fragment key={item.id}>
      <Row align="center" gutterWidth={60}>
        <Col xs={12} sm={12}>
          <TextInput
            id={`${item.id}_${item.pergunta}`}
            label="Título da pergunta"
            type="text"
            name={item.pergunta}
            value={item.pergunta}
            onChange={(e) => setItem('pergunta', e.target.value, item.id)}
            errorMessage={item.pergunta == "" ? "Favor preencher o campo para prosseguir!": ""}
            placeholder="EX: Como colar minha tag?"
          />
        </Col>
      </Row>
      <Row align="center" gutterWidth={60}>
        <Col xs={12}>
          <TextareaInput
            id = {`${item.id}_${item.resposta}`}
            label="Texto da resposta"
            type="text"
            name={item.resposta}
            value={item.resposta}
            onChange={(e) => setItem('resposta', e.target.value, item.id)}
            errorMessage={item.resposta == "" ? "Favor preencher o campo para prosseguir!": ""}
            placeholder="Ex: Para instalar seu adesivo..."
          />
        </Col>
      </Row>
    </React.Fragment>
  )

  const ButtonAddQuestionComponent = () => {
    return (
      <div style={{display: 'flex', alignItems: 'center', flexDirection: 'row-reverse', marginBottom: 32 }} >
        <Button id="btn_save_account" variant="icon" onClick={handleAddQuestion}>
          <Image src={ PlusIcon } alt="plus" />
        </Button>
        <Typography style={{ marginRight: 16 }} tag="span">
            Adicionar nova pergunta
        </Typography>
      </div>
    )
  };

  const ButtonRemoveQuestionComponent = (id) => {
    return (
      <div style={{display: 'flex', alignItems: 'center', flexDirection: 'row-reverse', marginBottom: 32 }} >
        <Button id="btn_remove_account" variant="icon" onClick={() => handleRemoveQuestion(id)}>
          <Image src={ MinusIcon } alt="minus" />
        </Button>
        <Typography style={{ marginRight: 16 }} tag="span">
            Remover a pergunta
        </Typography>
      </div>
    )
  };

  const ButtonContinueComponent = () => (
    <Col md={6}>
      <Row direction="row-reverse">
        <Button id="btn_save_account" disabled={IsDisableButton()} onClick={handleClickNext}>
          Continuar
        </Button>
      </Row>
    </Col>
  );

  const ButtonGoBackComponent = () => (
    <Col md={6}>
      <Button id="btn_voltar" type="button" onClick={() => onPreviousPage()}>
        Voltar
      </Button>
    </Col>
  );

  return (
    <Grid>
      <Row>
        <Col xs={12} sm={8}>
          <CardContainer>
            <Typography tag="h2" variant="secondary" spacing="mdx">
              Preencha as informações do bloco
            </Typography>
            <Row spacing="lg">
              <Col xs={12}>
                <ColorPicker
                  key="principal"
                  labelText="Cor principal do bloco"
                  hex={formik.values.questPrimaryColor}
                  onChangeColor={(value) =>
                    registration.setProp('questPrimaryColor', value, formik)
                  }
                />
              </Col>
            </Row>
            {QuestionsList.map(item => (
              <React.Fragment key={item.id}>
                { InputQuestionComponent(item) }
                { getLastItemQuestion(item.id) ? ButtonAddQuestionComponent(): ButtonRemoveQuestionComponent(item.id) }
              </React.Fragment>
            ))}
            <Row direction="row-reverse">
              {ButtonContinueComponent()}
              {ButtonGoBackComponent()}
            </Row>
          </CardContainer>
        </Col>
      </Row>
    </Grid>
  )
}

QuestionsPage.propTypes = {
  onNextPage: PropTypes.func.isRequired
}
