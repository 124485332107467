import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Col, Row } from 'components/common'
import * as S from './styled'
export const ShowItemOffer = ({ data }) => {
  return (
    <Grid>
      <Row spacing="md">
        <Col xs={12} sm={4}>
          <S.Label>Plano:</S.Label>
          <S.Value>{data.planOffers || '-'}</S.Value>
        </Col>
        <Col xs={12} sm={4}>
          <S.Label>Preço do plano:</S.Label>
          <S.Value>{data.planPriceOffers || '-'}</S.Value>
        </Col>
        <Col xs={12} sm={4}>
          <S.Label>Prioridade:</S.Label>
          <S.Value>{data.priorityOffers || '-'}</S.Value>
        </Col>
      </Row>
      <Row spacing="md">
        <Col xs={12} sm={4}>
          <S.Label>Tipo de critério:</S.Label>
          <S.Value>{data.kindOfCriterionOffers || '-'}</S.Value>
        </Col>
        <Col xs={12} sm={4}>
          <S.Label>Critério:</S.Label>
          <S.Value>{data.criterionOffers || '-'}</S.Value>
        </Col>
        <Col xs={12} sm={4}>
          <S.Label> Taxa Mensalidade:</S.Label>
          <S.Value>{data.monthlyTaxOffers || '-'}</S.Value>
        </Col>
      </Row>
      <Row spacing="md">
        <Col xs={12} sm={4}>
          <S.Label>Início da oferta:</S.Label>
          <S.Value>{data.startOffers || '-'}</S.Value>
        </Col>
        <Col xs={12} sm={4}>
          <S.Label>Fim da oferta:</S.Label>
          <S.Value>{data.endOffers || '-'}</S.Value>
        </Col>
        <Col xs={12} sm={4}>
          <S.Label>Meses de isenção:</S.Label>
          <S.Value>{data.monthsExemptionOffers || '-'}</S.Value>
        </Col>
      </Row>
      <Row spacing="md">
        <Col xs={12} sm={4}>
          <S.Label>Percentual de Isenção:</S.Label>
          <S.Value>{data.exemptionPercentageOffers || '-'}</S.Value>
        </Col>
        <Col xs={12} sm={4}>
          <S.Label>TAG:</S.Label>
          <S.Value>{data.tagOffers || '-'}</S.Value>
        </Col>
        <Col xs={12} sm={4}>
          <S.Label>Texto de suporte:</S.Label>
          <S.Value>{data.supportTextOfferAdd || '-'}</S.Value>
        </Col>
      </Row>
      <Row spacing="md">
        <Col xs={12}>
          <S.Label>Texto de apoio</S.Label>
          <S.Value>{data.textOffers || '-'}</S.Value>
        </Col>
      </Row>
      <Row spacing="md">
        <Col xs={12}>
          <S.Label>Habilitar este card como destaque nas opções:</S.Label>
          <S.Value>{data.enableHighlight ? <>&#9989;</> : ''}</S.Value>
        </Col>
      </Row>
    </Grid>
  )
}

ShowItemOffer.propTypes = {
  data: PropTypes.shape({
    planOffers: PropTypes.string,
    kindOfCriterionOffers: PropTypes.string,
    endOffers: PropTypes.string,
    textOffers: PropTypes.string,
    paymentSelected: PropTypes.string,
    monthsExemptionOffers: PropTypes.string,
    enableHighlight: PropTypes.bool,
    tagOffers: PropTypes.string,
    exemptionPercentageOffers: PropTypes.string,
    startOffers: PropTypes.string,
    supportTextOfferAdd: PropTypes.string,
    criterionOffers: PropTypes.string,
    monthlyTaxOffers: PropTypes.string,
    planPriceOffers: PropTypes.string,
    priorityOffers: PropTypes.string
  }).isRequired
}
