import styled from 'styled-components'

export const Header = styled.header`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.tertiary.light};
  display: flex;
  height: 10vh;
  justify-content: space-between;
  min-height: 75px;
  padding-left: ${({ theme }) => theme.spacing.max};
  padding-right: 320px;
  width: 100%;
`
export const HeaderInfo = styled.div`
  display: flex;
  flex-direction: column;
`

export const MenuContainer = styled.div`
  display: flex;
  min-height: 45px;
  padding: 0 20px 20px;

  button {
    font-size: 16px;
    font-weight: normal;

    &:hover {
      font-weight: bold;
    }
  }
`

export const HeaderMenu = styled.div`
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  align-items: center;
  cursor: pointer;
  display: flex;
  user-select: none;

  nav {
    &.primary-navigation {
      margin: 0 auto;
      display: block;
      text-align: center;

      ul li {
        list-style: none;
        margin: 0 auto;
        display: inline-block;
        position: relative;
        text-decoration: none;
        text-align: center;
      }

      li:hover {
        cursor: pointer;
      }

      ul li ul {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        padding-left: 0;
        left: 0;
        display: none;
        background: white;
      }

      ul li:hover > ul,
      ul li ul:hover {
        visibility: visible;
        opacity: 1;
        display: block;
        min-width: 250px;
        text-align: left;
        padding-top: 20px;
        box-shadow: 0px 3px 5px -1px #ccc;
      }

      ul li ul li {
        clear: both;
        width: 100%;
        text-align: left;
        margin-bottom: 20px;
        border-style: none;
      }

      ul li ul li a:hover {
        padding-left: 10px;
        border-left: 2px solid #3ca0e7;
        transition: all 0.3s ease;
      }
    }

    a {
      text-decoration: none;
    }

    ul li ul li a {
      transition: all 0.5s ease;
    }
  }
`
