import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { useFormik } from 'formik'
import { useSelector, useDispatch } from 'react-redux'
import {
  Typography,
  Button,
  TextInput,
  FileUpload,
  ColorPicker,
  TextareaInput,
  CardContainer,
  Grid,
  Col,
  Row,
  Form
} from 'components/common'
import { mainPanelSchema } from 'utils/schemas'
import { useRegistration } from 'hooks'
import { actionSetRegistration } from 'redux/actions/Registration/Data'

export const MainPanelPage = ({ onNextPage }) => {
  const registration = useRegistration()
  const dispatch = useDispatch()

  const {
    partnerData: { payload },
    data: { payload: dataFormGlobalStore }
  } = useSelector(({ registration }) => registration)

  const [fillForm, setFillForm] = useState(false)
  useEffect(() => {
    if (!isEmpty(payload) && !fillForm) {
      formik.setFieldValue('tag', dataFormGlobalStore.tag || payload.tag)
      formik.setFieldValue(
        'mainPainelPrimaryColor',
        dataFormGlobalStore.mainPainelPrimaryColor ||
          payload.mainPainelPrimaryColor
      )
      formik.setFieldValue(
        'mainPainelSecondaryColor',
        dataFormGlobalStore.mainPainelSecondaryColor ||
          payload.mainPainelSecondaryColor
      )
      formik.setFieldValue('title', dataFormGlobalStore.title || payload.title)
      formik.setFieldValue(
        'supportText',
        dataFormGlobalStore.supportText || payload.supportText
      )
      formik.setFieldValue(
        'uploadImage',
        dataFormGlobalStore.uploadImage || payload.uploadImage
      )
      
      // setando os valores de todas as cores da tela


      dispatch(
        actionSetRegistration({
          ...dataFormGlobalStore,
          mainPainelPrimaryColor: dataFormGlobalStore.mainPainelPrimaryColor || payload.mainPainelPrimaryColor,
          mainPainelSecondaryColor: dataFormGlobalStore.mainPainelSecondaryColor || payload.mainPainelSecondaryColor,
          title: dataFormGlobalStore.title || payload.title,
          supportText: dataFormGlobalStore.supportText || payload.supportText,
          uploadImage: dataFormGlobalStore.uploadImage || payload.uploadImage,
          infoTag: dataFormGlobalStore.tag || payload.tag
        })
      )
      setFillForm(true)
    }
  }, [payload, dataFormGlobalStore])

  const handleSubmit = () => {
    if(dataFormGlobalStore.tag)
      registration.setProp('infoTag', dataFormGlobalStore.tag, formik);

    onNextPage()
  }

  const formik = useFormik({
    initialValues: {
      tag: dataFormGlobalStore.tag || '',
      title: dataFormGlobalStore.title || '',
      supportText: dataFormGlobalStore.supportText || '',
      mainPainelPrimaryColor: dataFormGlobalStore.mainPainelPrimaryColor || '',
      mainPainelSecondaryColor: dataFormGlobalStore.mainPainelSecondaryColor || '',
      uploadImage: dataFormGlobalStore.uploadImage || ''
    },
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: handleSubmit,
    validationSchema: () => mainPanelSchema
  })

  return (
    <Grid>
      <Form onSubmit={formik.handleSubmit}>
        <Row>
          <Col xs={12} sm={8}>
            <CardContainer>
              <Typography tag="h2" variant="secondary" spacing="smx">
                Preencha as informações do bloco
              </Typography>
              <Typography tag="disclaimer" spacing="mdx">
                * Campo de preenchimento obrigatório
              </Typography>
              <Row spacing="lg">
                <Col xs={12} sm={6}>
                  <ColorPicker
                    key="principal"
                    labelText="Cor principal do bloco"
                    hex={formik.values.mainPainelPrimaryColor}
                    onChangeColor={(value) =>
                      registration.setProp(
                        'mainPainelPrimaryColor',
                        value,
                        formik
                      )
                    }
                  />
                </Col>
                <Col xs={12} sm={6}>
                  <ColorPicker
                    key="principal"
                    labelText="Cor secundária do bloco"
                    hex={formik.values.mainPainelSecondaryColor}
                    onChangeColor={(value) =>
                      registration.setProp(
                        'mainPainelSecondaryColor',
                        value,
                        formik
                      )
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6}>
                  <TextInput
                    id="tag"
                    label="TAG"
                    type="text"
                    name="tag"
                    value={formik.values.tag}
                    onChange={(event) => registration.onChange(event, formik)}
                    onBlur={formik.handleBlur}
                    errorMessage={
                      (formik.touched.tag && formik.errors.tag) || ''
                    }
                    placeholder="Ex: contratação online, simples e rápida!"
                  />
                </Col>
                <Col xs={12} sm={6}>
                  <TextInput
                    id="title"
                    label="Título do bloco"
                    type="text"
                    name="title"
                    value={formik.values.title}
                    onChange={(event) => registration.onChange(event, formik)}
                    onBlur={formik.handleBlur}
                    errorMessage={
                      (formik.touched.title && formik.errors.title) || ''
                    }
                    placeholder="Ex: Praticidade, controle.."
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <TextareaInput
                    id="supportText"
                    label="Texto de apoio"
                    name="supportText"
                    value={formik.values.supportText}
                    onChange={(event) => registration.onChange(event, formik)}
                    onBlur={formik.handleBlur}
                    errorMessage={
                      (formik.touched.supportText &&
                        formik.errors.supportText) ||
                      ''
                    }
                    placeholder="Ex: É um fato conhecido de todos que..."
                  />
                </Col>
              </Row>
              <Row direction="row-reverse">
                <Button id="btn_save_account" type="submit">
                  Continuar
                </Button>
              </Row>
            </CardContainer>
          </Col>
          <Col xs={12} sm={3}>
            <CardContainer>
              <FileUpload
                id="uploadImage"
                name="uploadImage"
                value={formik.values.uploadImage}
                onClick={() => {
                  formik.setFieldError('uploadImage', 'Campo obrigatório')
                  formik.setFieldTouched('uploadImage', true, false)
                }}
                onChange={(value) =>
                  registration.setProp('uploadImage', value, formik)
                }
                onBlur={formik.handleBlur}
                errorMessage={
                  (formik.touched.uploadImage && formik.errors.uploadImage) ||
                  ''
                }
              />
            </CardContainer>
          </Col>
        </Row>
      </Form>
    </Grid>
  )
}

MainPanelPage.propTypes = {
  onNextPage: PropTypes.func.isRequired
}
