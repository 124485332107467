import React from 'react'
import LogoConectCar from 'assets/images/logo.png'
import { Image } from 'components/common'
import { Link } from 'react-router-dom'
import * as S from './styled'

const SideBar = () => {
  return (
    <S.WrapperMenu>
      <Link to="/">
        <Image
          src={LogoConectCar}
          alt="logo-conectCar"
          width="112px"
          height="25px"
        />
      </Link>
      <S.MenuTitle>LANDING PAGES</S.MenuTitle>
      <S.Menu>
      </S.Menu>
    </S.WrapperMenu>
  )
}

export default SideBar
