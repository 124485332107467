import React, { useState, useEffect } from 'react'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { Typography, Loader, Image, Alert } from 'components/common'
import { actionCloseSnackbar } from 'redux/actions/Dialogs/Snackbar'
import LoginPicture from 'assets/images/login-picture.svg'

import * as S from './styled'

export const LogOffTemplate = ({ children }) => {
  const dispatch = useDispatch()
  const {
    wrapper: {
      spinner: { payload: showSpinner }
    },
    dialogs: {
      snackbar: { payload: snackbar }
    }
  } = useSelector((state) => state)

  const [snackbarObj, setSnackbarObj] = useState({})

  useEffect(() => {
    if (!isEmpty(snackbar)) {
      setSnackbarObj(snackbar)
    }
  }, [snackbar])

  return (
    <S.Container>
      <S.FormLoginContainer>
        {snackbarObj.show && (
          <Alert
            variant={snackbarObj.type}
            onClose={() => dispatch(actionCloseSnackbar())}
            spacing="mdl"
          >
            {snackbarObj.message || ''}
          </Alert>
        )}
        <Loader variant="default" show={showSpinner} />
        {!showSpinner && children}

        <S.Footer>
          <Typography
            tag="text"
            variant="secondary"
            style={{ fontWeight: 'bold' }}
          >
            Ambiente seguro · ConectCar CNPJ: 16.577.631/0002-99
            <br />© - Todos os direitos reservados
          </Typography>
        </S.Footer>
      </S.FormLoginContainer>
      <S.Banner>
        <Image src={LoginPicture} alt="logo-conectCar" />
      </S.Banner>
    </S.Container>
  )
}

LogOffTemplate.propTypes = {
  children: PropTypes.node.isRequired
}
