import {
  CREATE_LANDING_PAGE_DATA_FAILURE,
  CREATE_LANDING_PAGE_DATA_REQUEST,
  CREATE_LANDING_PAGE_DATA_SUCCESS
} from 'redux/constants/LandingPage/Data'

const initialState = {
  loading: false,
  error: false,
  payload: {}
}

const Persist = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_LANDING_PAGE_DATA_REQUEST:
      return {
        payload: {},
        loading: true,
        error: false
      }
    case CREATE_LANDING_PAGE_DATA_SUCCESS:
      return {
        payload: action.payload.dados,
        loading: false,
        error: false
      }
    case CREATE_LANDING_PAGE_DATA_FAILURE:
      return {
        payload: {},
        loading: false,
        error: true
      }
    default:
      return state
  }
}

export default Persist
