import {
  GET_ALL_LANDING_PAGE_DATA_FAILURE,
  GET_ALL_LANDING_PAGE_DATA_REQUEST,
  GET_ALL_LANDING_PAGE_DATA_SUCCESS
} from 'redux/constants/LandingPage/Data'

const initialState = {
  loading: false,
  error: false,
  payload: {
    paginas: [],
    landingPages: []
  }
}

const Data = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_LANDING_PAGE_DATA_REQUEST:
      return {
        payload: {
          paginas: [],
          landingPages: []
        },
        loading: true,
        error: false
      }
    case GET_ALL_LANDING_PAGE_DATA_SUCCESS:
      return {
        payload: action.payload.dados,
        loading: false,
        error: false
      }
    case GET_ALL_LANDING_PAGE_DATA_FAILURE:
      return {
        payload: {
          paginas: [],
          landingPages: []
        },
        loading: false,
        error: true
      }
    default:
      return state
  }
}

export default Data
