import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { forEach } from 'lodash'
import { useFormik } from 'formik'
import { useSelector, useDispatch } from 'react-redux'
import {
  Typography,
  CardContainer,
  Grid,
  Col,
  Row,
  Button,
  ColorPicker,
  TextInput,
  TextareaInput,
  Divider,
  CheckBoxGroup,
  Form,
  Accordion
} from 'components/common'
import { offersSchema } from 'utils/schemas'
import { useRegistration } from 'hooks'
import { ItemOffer } from './ItemOffer'
import { ItemOfferPJ } from './ItemOfferPJ'
import { ShowItemOffer } from './ShowItemOffer'
import { ShowItemOfferPJ } from './ShowItemOfferPJ'
import { actionSetRegistration } from 'redux/actions/Registration/Data'

export const OffersPage = ({ onPreviousPage,
  onNextPage,
  plansList,
  criterionsList,
  monthlyFeeList
}) => {
  const registration = useRegistration()

  const CHECKBOX_LIST = [
    {
      value: 'PRAVOCE',
      label: 'Para você'
    },
    {
      value: 'PARASUAEMPRESA',
      label: 'Para empresa'
    }
  ]

  const {
    partnerData: { payload },
    data: { payload: dataFormGlobalStore }
  } = useSelector(({ registration }) => registration)
  const dispatch = useDispatch()

  const [offerList, setOfferList] = useState([])
  const [offerEdit, setOfferEdit] = useState({})
  const [offerEditPJ, setOfferEditPJ] = useState({})
  const [fillForm, setFillForm] = useState(false)

  useEffect(() => {
    if (!fillForm) {
      formik.setFieldValue(
        'offersPrimaryColor',
        dataFormGlobalStore.offersPrimaryColor || payload.offersPrimaryColor
      )
      formik.setFieldValue(
        'offersSecondaryColor',
        dataFormGlobalStore.offersSecondaryColor || payload.offersSecondaryColor
      )

      formik.setFieldValue(
        'titleOffers',
        dataFormGlobalStore.titleOffers || payload.titleOffers
      )
      formik.setFieldValue(
        'supportTextOffers',
        dataFormGlobalStore.supportTextOffers || payload.supportTextOffers
      )

      if (Array.isArray(dataFormGlobalStore.listWithOffersTypes) && dataFormGlobalStore.listWithOffersTypes.length) {
        setOfferList(dataFormGlobalStore.listWithOffersTypes[0].list)
      } else {
        const newOfferList = []
        forEach(payload.listPlans, (item, index) => {
          newOfferList.push({
            // tambem usado como chave junto com titulo do plano
            typePerson: item.tipoCliente,
            // titulo do plano é chave, ou seja, nao pode ter mais de uma oferta na LP usando mesmo Plano
            planOffers: item.tituloPlano,
            kindOfCriterionOffers: item.criterios.length > 0 && item.criterios[0].tipo,
            endOffers: `${item.dataFim.substring(8, 10)}/${item.dataFim.substring(5, 7)}/${item.dataFim.substring(0, 4)}`,
            textOffers: item.textoApoio,
            monthsExemptionOffers: item.quantidadeMesesIsencao,
            enableHighlight: item.planoDestaque,
            tagOffers: item.textoTag,
            exemptionPercentageOffers: item.percentualIsencao,
            startOffers: `${item.dataInicio.substring(8, 10)}/${item.dataInicio.substring(5, 7)}/${item.dataInicio.substring(0, 4)}`,
            supportTextOfferAdd: item.textoSuporte,
            criterionOffers: item.criterios.length > 0 && item.criterios[0].valor,
            monthlyTaxOffers: item.taxaMensalidadeId,
            planPriceOffers: item.valorPlano,
            priorityOffers: item.prioridade,
            index,
            id: item.tipoCliente + '_' + item.tituloPlano
          })
        })
        setOfferList(newOfferList)
      }

      dispatch(
        actionSetRegistration({
          ...dataFormGlobalStore,
          offersPrimaryColor: dataFormGlobalStore.offersPrimaryColor || payload.offersPrimaryColor,
          offersSecondaryColor: dataFormGlobalStore.offersSecondaryColor || payload.offersSecondaryColor,
          titleOffers: dataFormGlobalStore.titleOffers || payload.titleOffers,
          supportTextOffers: dataFormGlobalStore.supportTextOffers || payload.supportTextOffers
        })
      )
      setFillForm(true)
    }
  }, [payload, dataFormGlobalStore])

  const handleSubmit = () => {
    if (offerList.length > 0) {
      handleAddRegister();
      onNextPage()
    }
  }

  const handleAddRegister = () => {
    const listWithOffersTypes = []

    listWithOffersTypes.push({
      list: offerList
    })

    registration.setProp('listWithOffersTypes', listWithOffersTypes, formik)
  }

  const handleAddOffer = (values) => {
    const newOfferList = [...offerList]

    newOfferList.push({
      ...values,
      typePerson: formik.values.typePerson,
      id: formik.values.typePerson + '_' + values.planOffers
    })

    setOfferList(newOfferList)
  }

  const handleEditOfferSelected = (values) => {
    let editItem = offerList.find((offer) => offer.id == values.id);
    let restList = offerList.filter((offer) => offer.id != values.id) || [];

    editItem = {
      ...values,
      typePerson: formik.values.typePerson,
      criterionOffers: dataFormGlobalStore.criterionOffers
    }

    setOfferList([...restList, editItem])
    setOfferEdit({})
    setOfferEditPJ({})

    handleAddRegister()
  }

  const formik = useFormik({
    initialValues: {
      titleOffers: dataFormGlobalStore.titleOffers,
      supportTextOffers: dataFormGlobalStore.supportTextOffers,
      offersPrimaryColor: dataFormGlobalStore.offersPrimaryColor,
      offersSecondaryColor: dataFormGlobalStore.offersSecondaryColor,
      typePerson: dataFormGlobalStore.typePerson || 'PRAVOCE'
    },
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: handleSubmit,
    validationSchema: () => offersSchema
  })

  const handleDeleteOffer = (item) => {
    let deleteList = offerList.filter(x => x != item) || [];

    setOfferList(deleteList)
    setOfferEdit({});

    handleAddRegister()
  }

  const handleSelectedOffer = (offer) => {
    registration.setProp('typePerson', offer.typePerson, formik)

    if (offer.typePerson === 'PRAVOCE') {
      setOfferEdit({ ...offer })
    } else {
      setOfferEditPJ(offer)
    }

    handleAddRegister()
  }

  return (
    <Grid>
      <Row>
        <Col xs={12} sm={8}>
          <CardContainer>
            <Typography tagOffers="h2" variant="secondary" spacing="mdx">
              Preencha as informações do bloco
            </Typography>
            <Row spacing="lg">
              <Col xs={12} sm={6}>
                <ColorPicker
                  key="principal"
                  labelText="Cor principal do bloco"
                  hex={formik.values.offersPrimaryColor}
                  onChangeColor={(value) =>
                    registration.setProp('offersPrimaryColor', value, formik)
                  }
                />
              </Col>
              <Col xs={12} sm={6}>
                <ColorPicker
                  key="principal"
                  labelText="Cor secundária do bloco"
                  hex={formik.values.offersSecondaryColor}
                  onChangeColor={(value) =>
                    registration.setProp('offersSecondaryColor', value, formik)
                  }
                />
              </Col>
            </Row>
            <Form onSubmit={formik.handleSubmit}>
              <Row>
                <Col xs={12}>
                  <TextInput
                    id="titleOffers"
                    label="Título do bloco*"
                    type="text"
                    name="titleOffers"
                    value={formik.values.titleOffers}
                    onChange={(event) => registration.onChange(event, formik)}
                    onBlur={formik.handleBlur}
                    errorMessage={
                      (formik.touched.titleOffers &&
                        formik.errors.titleOffers) ||
                      ''
                    }
                    placeholder="Ex: Conheça as ofertas"
                    maxLength={120}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <TextareaInput
                    id="supportTextOffers"
                    label="Texto de apoio*"
                    name="supportTextOffers"
                    value={formik.values.supportTextOffers}
                    onChange={(event) => registration.onChange(event, formik)}
                    onBlur={formik.handleBlur}
                    errorMessage={
                      (formik.touched.supportTextOffers &&
                        formik.errors.supportTextOffers) ||
                      ''
                    }
                    placeholder="Ex: As melhores ofertas da ConectCar..."
                    maxLength={800}
                  />
                </Col>
              </Row>
            </Form>

            <Divider spacing="mdl" top="smx" />

            <Typography tag="h2" variant="secondary" spacing="mdx">
              Opções de ofertas
            </Typography>

            <CheckBoxGroup
              checkboxList={CHECKBOX_LIST}
              onSelected={(value) => {
                registration.setProp('typePerson', value, formik)
                setOfferEdit({})
                setOfferEditPJ({})
              }}
              value={formik.values.typePerson}
            />
            {formik.values.typePerson === 'PRAVOCE' && (
              <ItemOffer
                plansList={plansList}
                criterionsList={criterionsList}
                monthlyFeeList={monthlyFeeList}
                onAddOffer={handleAddOffer}
                onEditOffer={handleEditOfferSelected}
                edit={offerEdit}
              />
            )}
            {formik.values.typePerson === 'PARASUAEMPRESA' && (
              <ItemOfferPJ
                plansList={plansList}
                onAddOffer={handleAddOffer}
                onEditOffer={handleEditOfferSelected}
                edit={offerEditPJ}
              />
            )}
            <Row>
              <Col xs={12}>
                {offerList.map((item, index) => (
                  <Accordion
                    key={index}
                    title={`${item.typePerson === 'PRAVOCE' ? 'Para você' : 'Para empresa'} - ${item.planOffers} : ${item.tagOffers}`}
                    onDelete={() => handleDeleteOffer(item)}
                    onEdit={() => handleSelectedOffer(item, index)}
                  >
                    {item.typePerson === 'PRAVOCE' && (
                      <ShowItemOffer data={item} />
                    )}
                    {item.typePerson === 'PARASUAEMPRESA' && (
                      <ShowItemOfferPJ data={item} />
                    )}
                  </Accordion>
                ))}
              </Col>
            </Row>
            <Row>
              {offerList.length == 0 ? (
                <Col xs={12}>
                  <div className="sc-iJKOTD knFAWh">** Você precisa ter no minimo uma oferta para continuar com o cadastro **</div>
                </Col>
              ) : ''}
            </Row>
            <br />
            <Row direction="row-reverse">
              <Col md={6}>
                <Row direction="row-reverse">
                  <Button
                    id="btn_save_account"
                    type="submit"
                    onClick={() => handleSubmit()}
                  >
                    Continuar
                  </Button>
                </Row>
              </Col>
              <Col md={6}>
                <Button id="btn_voltar" type="button" onClick={() => onPreviousPage()}>
                  Voltar
                </Button>
              </Col>
            </Row>
          </CardContainer>
        </Col>
      </Row>
    </Grid>
  )
}

OffersPage.propTypes = {
  onNextPage: PropTypes.func.isRequired,
  plansList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  criterionsList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  monthlyFeeList: PropTypes.arrayOf(PropTypes.shape({})).isRequired
}
