import { useDispatch, useSelector } from 'react-redux'
import { forEach, findIndex } from 'lodash'
import { actionSetRegistration } from 'redux/actions/Registration/Data'

export const useRegistration = () => {
  const dispatch = useDispatch()
  const {
    data: { payload }
  } = useSelector(({ registration }) => registration)

  const onChange = (event, formik) => {
    formik.handleChange(event)
    dispatch(
      actionSetRegistration({
        ...payload,
        [event.target.name]: event.target.value
      })
    )
  }

  const setManyProp = (listProps, formik) => {
    forEach(listProps, (value, key) => {
      formik.setFieldValue(key, value)
      formik.setFieldTouched(key, false)
    })
    dispatch(
      actionSetRegistration({
        ...payload,
        ...listProps
      })
    )
  }

  const setProp = (key, value, formik) => {
    formik.setFieldValue(key, value)
    dispatch(
      actionSetRegistration({
        ...payload,
        [key]: value
      })
    )
  }

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })

  const setFileToBase64 = async (key, file, formik) => {
    if (file) {
      const fileBase64 = await toBase64(file)
      formik.setFieldValue(key, file)
      dispatch(
        actionSetRegistration({
          ...payload,
          [key]: fileBase64
        })
      )
    }
  }

  const idExist = (id, listIds) => {
    return findIndex(listIds, ['id', id]) !== -1
  }

  const generateFormId = (listIds, listCompleteIds) => {
    let newId
    forEach(listCompleteIds, (id) => {
      if (!idExist(id, listIds)) {
        newId = id
        return false
      }
    })
    return newId
  }

  return {
    generateFormId,
    setManyProp,
    onChange,
    setProp,
    setFileToBase64
  }
}
