import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Image, Typography, Button } from 'components/common'
import LogoConectCar from 'assets/images/logo.png'
import { useIsAuthenticated, useMsal } from "@azure/msal-react"
import { loginRequest } from "../../authConfig";
import * as S from './styled'

export const LoginPage = () => {
  const history = useHistory()
  const isAuthenticated = useIsAuthenticated()
  const { instance } = useMsal()

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/')
    }
  }, [isAuthenticated])

  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <S.BoxImage>
        <Image
          src={LogoConectCar}
          alt="logo-conectCar"
          width="112px"
          height="25px"
        />
      </S.BoxImage>
      <Typography tag="h2" variant="secondary" spacing="sm">
        Bem vindo ao ambiente de edição de Temas da ConectCar
      </Typography>
      <Typography tag="text" spacing="sm">
        Você está fazendo login para a edição dos templates de landing page
      </Typography>
      <S.BoxAction>
        <Button id="btn_login" onClick={() => handleLogin()} fullWidth>
          Entrar
        </Button>
      </S.BoxAction>
    </>
  )
}