import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { isEmpty } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import {
  Typography,
  Button,
  TextInput,
  FileUpload,
  TextareaInput,
  CardContainer,
  Grid,
  Col,
  Row,
  Form
} from 'components/common'
import { themeSchema } from 'utils/schemas'
import { useRegistration } from 'hooks'
import {
  actionCreateLandingPage,
  actionUpdateLandingPage,
  actionSetLandingPagePersistData
} from 'redux/actions/LandingPage/Data'
import { actionSetRegistration } from 'redux/actions/Registration/Data'
import { actionClearLandingPagePersistUpdateData } from 'redux/actions/LandingPage/Data'

const ThemePage = ({ onPreviousPage }) => {
  const history = useHistory()
  const { id } = useParams()
  const registration = useRegistration()
  const dispatch = useDispatch()
  const [fillForm, setFillForm] = useState(false)

  const {
    selected: {payload: { status }}
  } = useSelector(({ landingPage }) => landingPage);
  
  const {
    partnerData: { payload },
    data: { payload: dataFormGlobalStore },
    persist: { payload: persistResponse },
    persistUpdate: {payload: persistUpdate}
  } = useSelector(({ registration }) => registration)

  useEffect(() => {
    if (!isEmpty(payload) && !fillForm) {
      formik.setFieldValue(
        'titleTheme',
        dataFormGlobalStore.titleTheme || payload.titleTheme
      )
      formik.setFieldValue(
        'descriptionTheme',
        dataFormGlobalStore.descriptionTheme || payload.descriptionTheme
      )
      formik.setFieldValue(
        'legalTextTheme',
        dataFormGlobalStore.legalTextTheme || payload.legalTextTheme
      )
      formik.setFieldValue(
        'dnsTheme',
        dataFormGlobalStore.dnsTheme || payload.dnsTheme
      )
      formik.setFieldValue(
        'uploadImageTheme',
        dataFormGlobalStore.uploadImageTheme || payload.uploadImageTheme
      )
      formik.setFieldValue(
        'uploadTerm',
        dataFormGlobalStore.uploadTerm || payload.uploadTerm
      )
      formik.setFieldValue(
        'uploadPdf',
        dataFormGlobalStore.uploadPdf || payload.uploadPdf
      )

      dispatch(
        actionSetRegistration({
          ...dataFormGlobalStore,
          titleTheme: dataFormGlobalStore.titleTheme || payload.titleTheme,
          descriptionTheme:
            dataFormGlobalStore.descriptionTheme || payload.descriptionTheme,
          legalTextTheme:
            dataFormGlobalStore.legalTextTheme || payload.legalTextTheme,
          dnsTheme: dataFormGlobalStore.dnsTheme || payload.dnsTheme,
          uploadImageTheme:
            dataFormGlobalStore.uploadImageTheme || payload.uploadImageTheme,
          uploadTerm: dataFormGlobalStore.uploadTerm || payload.uploadTerm,
          uploadPdf: dataFormGlobalStore.uploadPdf || payload.uploadPdf
        })
      )
      setFillForm(true)
    }
  }, [payload, dataFormGlobalStore])

  useEffect(() => {    
    if (!isEmpty(persistResponse)) {
      dispatch(actionSetLandingPagePersistData({}))
      setTimeout(() => {
        history.push('/')
      }, 1000)
    }
  }, [persistResponse])

  useEffect(() => {    
    if (!isEmpty(persistUpdate)) {
      dispatch(actionClearLandingPagePersistUpdateData({}))
      setTimeout(() => {
        history.push('/')
      }, 1000)
    }
  }, [persistUpdate])

  const handleSubmit = (status) => {
    if (!id) {
      dispatch(actionCreateLandingPage(dataFormGlobalStore, status))
    } else {
      dispatch(actionUpdateLandingPage(id, dataFormGlobalStore, status))
    }
  }

  const handleDraw = () => {
    if(isValidImages())
      handleSubmit("RASCUNHO")
  }

  const handlePublicLandingPage = () => {
    if(isValidImages())
      handleSubmit("PUBLICADO")
  }

  const isValidImages = () => {
    var validation = true;

    if(!formik.values.uploadImageTheme) {
      formik.setFieldError('uploadImageTheme', 'Campo obrigatório')
      formik.setFieldTouched('uploadImageTheme', true, false)
      validation = false;
    }

    if(!formik.values.uploadTerm) {
      formik.setFieldError('uploadTerm', 'Campo obrigatório')
      formik.setFieldTouched('uploadTerm', true, false)
      validation = false;
    }

    if(!formik.values.uploadPdf) {
      formik.setFieldError('uploadPdf', 'Campo obrigatório')
      formik.setFieldTouched('uploadPdf', true, false)
      validation = false;
    }

    return validation;
  }
  
  const formik = useFormik({
    initialValues: {
      dnsTheme: dataFormGlobalStore.dnsTheme || '',
      titleTheme: dataFormGlobalStore.titleTheme || '',
      descriptionTheme: dataFormGlobalStore.descriptionTheme || '',
      legalTextTheme: dataFormGlobalStore.legalTextTheme || '',
      uploadImageTheme: dataFormGlobalStore.uploadImageTheme || '',
      uploadTerm: dataFormGlobalStore.uploadTerm || '',
      uploadPdf: dataFormGlobalStore.uploadPdf || '',
      status
    },
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: handleSubmit,
    validationSchema: () => themeSchema
  })

  return (
    <Grid>
      <Form onSubmit={formik.handleSubmit}>
        <Row>
          <Col xs={12} sm={8}>
            <CardContainer>
              <Typography tag="h2" variant="secondary" spacing="mdx">
                Preencha as informações do bloco
              </Typography>
              <Row>
                <Col xs={12}>
                  <TextInput
                    id="dnsTheme"
                    label="DNS"
                    type="text"
                    name="dnsTheme"
                    value={formik.values.dnsTheme}
                    errorMessage={
                      (formik.touched.dnsTheme && formik.errors.dnsTheme) || ''
                    }
                    onChange={(event) => registration.onChange(event, formik)}
                    onBlur={formik.handleBlur}
                    placeholder="Ex: wolks.conectcar.com"
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <TextInput
                    id="titleTheme"
                    label="Título da Landing Page"
                    type="text"
                    name="titleTheme"
                    value={formik.values.titleTheme}
                    onChange={(event) => registration.onChange(event, formik)}
                    onBlur={formik.handleBlur}
                    errorMessage={
                      (formik.touched.titleTheme && formik.errors.titleTheme) ||
                      ''
                    }
                    placeholder="Ex: Conectcar e Porto Seguro"
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <TextareaInput
                    id="descriptionTheme"
                    label="Texto de descrição(Meta descriptionTheme)"
                    name="descriptionTheme"
                    value={formik.values.descriptionTheme}
                    onChange={(event) => registration.onChange(event, formik)}
                    onBlur={formik.handleBlur}
                    errorMessage={
                      (formik.touched.descriptionTheme &&
                        formik.errors.descriptionTheme) ||
                      ''
                    }
                    placeholder="Ex: Com Conectcar, você passa direto, mas sem passar vontade!"
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <TextareaInput
                    id="legalTextTheme"
                    label="Texto jurídico(Rodapé da landing page)"
                    name="legalTextTheme"
                    value={formik.values.legalTextTheme}
                    onChange={(event) => registration.onChange(event, formik)}
                    onBlur={formik.handleBlur}
                    errorMessage={
                      (formik.touched.legalTextTheme &&
                        formik.errors.legalTextTheme) ||
                      ''
                    }
                    placeholder="Ex: Oferta válida para clientes pessoa física que adquirirem..."
                  />
                </Col>
              </Row>
              <Row direction="row-reverse">
                <Col md={3}>
                  <Button 
                    id="btn_save_account" 
                    onClick={handlePublicLandingPage}
                    >
                    Publicar
                  </Button>
                </Col>
                {status != "PUBLICADO" ? (
                  <Col md={3}>
                    <Button
                      id="btn_save_account"
                      style={{ marginRight: 24 }}
                      onClick={handleDraw}
                    >
                      Rascunho
                    </Button>
                  </Col>
                ): ''}
                <Col md={6}>
                  <Button id="btn_voltar" type="button" onClick={() => onPreviousPage()}>
                    Voltar
                  </Button>
                </Col>
              </Row>
            </CardContainer>
          </Col>
          <Col xs={12} sm={3}>
            <CardContainer>
              <FileUpload
                title="Carregar logo"
                id="uploadImageTheme"
                name="uploadImageTheme"
                value={formik.values.uploadImageTheme}
                onClick={() => {
                  formik.setFieldError('uploadImageTheme', 'Campo obrigatório')
                  formik.setFieldTouched('uploadImageTheme', true, false)
                }}
                onChange={(value) => {
                  registration.setProp('uploadImageTheme', value, formik)
                }}
                onBlur={formik.handleBlur}
                errorMessage={
                  (formik.touched.uploadImageTheme &&
                    formik.errors.uploadImageTheme) ||
                  ''
                }
              />
              <FileUpload
                title="Carregar termo de adesão"
                spacing="mdx"
                variant="document"
                id="uploadTerm"
                name="uploadTerm"
                value={formik.values.uploadTerm}
                onClick={() => {
                  formik.setFieldError('uploadTerm', 'Campo obrigatório')
                  formik.setFieldTouched('uploadTerm', true, false)
                }}
                onBlur={formik.handleBlur}
                errorMessage={
                  (formik.touched.uploadTerm && formik.errors.uploadTerm) || ''
                }
                onChange={(value) =>
                  registration.setProp('uploadTerm', value, formik)
                }
              />
              <FileUpload
                title="Carregar regulamento da campanha"
                variant="document"
                spacing="mdx"
                id="uploadPdf"
                name="uploadPdf"
                value={formik.values.uploadPdf}
                onClick={() => {
                  formik.setFieldError('uploadPdf', 'Campo obrigatório')
                  formik.setFieldTouched('uploadPdf', true, false)
                }}
                onBlur={formik.handleBlur}
                errorMessage={
                  (formik.touched.uploadPdf && formik.errors.uploadPdf) || ''
                }
                onChange={(value) =>
                  registration.setProp('uploadPdf', value, formik)
                }
              />
            </CardContainer>
          </Col>
        </Row>
      </Form>
    </Grid >
  )
}

ThemePage.defaultProps = {
  className: null,
  containerSize: '100'
}

export { ThemePage }
