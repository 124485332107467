import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import { unRegisterServiceWorker } from 'config/initializer'

import App from './App'
import store from './redux'
import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { msalConfig } from './authConfig'
import { login, setUserName } from 'services/auth'
import { isEmpty } from 'lodash'

const msalInstance = new PublicClientApplication(msalConfig)

// Evento disparado sempre que ocorre o login no Azure AD
msalInstance
  .handleRedirectPromise()
  .then((tokenResponse) => {
    if (!isEmpty(tokenResponse)) {
      login(tokenResponse.accessToken)
      setUserName(tokenResponse.account.name)
    }
  })
  .catch((error) => {
    console.log(error)
  })

ReactDOM.render(
  <MsalProvider instance={msalInstance}>
    <Provider store={store}>
      <App />
    </Provider>
  </MsalProvider>,
  document.getElementById('root')
)

unRegisterServiceWorker()
