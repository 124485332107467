import { SHOW_SNACKBAR } from 'redux/constants/Dialogs/Snackbar'
import { SHOW_SPINNER } from 'redux/constants/Wrapper/Spinner'
import {
  POST_SIGNIN_FAILURE,
  POST_SIGNIN_REQUEST,
  POST_SIGNIN_SUCCESS,
  POST_SIGNIN_FORBIDDEN,
  LOGOUT
} from 'redux/constants/Auth/SignIn'
import {
  GET_ALL_LANDING_PAGE_DATA_FAILURE,
  GET_ALL_LANDING_PAGE_DATA_REQUEST,
  GET_ALL_LANDING_PAGE_DATA_SUCCESS,
  CREATE_LANDING_PAGE_DATA_FAILURE,
  CREATE_LANDING_PAGE_DATA_REQUEST,
  CREATE_LANDING_PAGE_DATA_SUCCESS,
  GET_LANDING_PAGE_BY_ID_FAILURE,
  GET_LANDING_PAGE_BY_ID_SUCCESS,
  GET_LANDING_PAGE_BY_ID_REQUEST,
  UPDATE_LANDING_PAGE_DATA_FAILURE,
  UPDATE_LANDING_PAGE_DATA_REQUEST,
  UPDATE_LANDING_PAGE_DATA_SUCCESS
} from 'redux/constants/LandingPage/Data'

const payloadError = (data) => {
  var objReturn = {
    show: true,
    type: 'error',
    message: 'Erro ao realizar ação. Tente novamente mais tarde!'
  }

  if(data && data.mensagens && Array.isArray(data.mensagens.Erro) && data.mensagens.Erro.length)
  {
    objReturn.message = data.mensagens.Erro[0]
  } else {
    var requiredFieldsMessage = ''
    for (const property in data.mensagens) {
      var msg = data.mensagens[property]
      if(Array.isArray(msg) && msg.length && msg[0].endsWith('must not be empty.')) {
        requiredFieldsMessage += msg[0].replace('must not be empty.', 'deve ser preenchido.')
        // TODO: Incluir quebra de linha para mais erros
      }
    }
    if(requiredFieldsMessage !== '')
    {
      objReturn.message = requiredFieldsMessage;
    }
  }

  return objReturn
}

export const spinnerMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case POST_SIGNIN_REQUEST:
    case GET_ALL_LANDING_PAGE_DATA_REQUEST:
    case CREATE_LANDING_PAGE_DATA_REQUEST:
    case GET_LANDING_PAGE_BY_ID_REQUEST:
    case UPDATE_LANDING_PAGE_DATA_REQUEST:
      store.dispatch({
        type: SHOW_SPINNER,
        payload: true
      })
      break
    case POST_SIGNIN_FAILURE:
    case POST_SIGNIN_SUCCESS:
    case POST_SIGNIN_FORBIDDEN:
    case GET_ALL_LANDING_PAGE_DATA_FAILURE:
    case GET_ALL_LANDING_PAGE_DATA_SUCCESS:
    case CREATE_LANDING_PAGE_DATA_FAILURE:
    case CREATE_LANDING_PAGE_DATA_SUCCESS:
    case GET_LANDING_PAGE_BY_ID_FAILURE:
    case GET_LANDING_PAGE_BY_ID_SUCCESS:
    case UPDATE_LANDING_PAGE_DATA_FAILURE:
    case UPDATE_LANDING_PAGE_DATA_SUCCESS:
      store.dispatch({
        type: SHOW_SPINNER,
        payload: false
      })
      break
    default:
      break
  }
  next(action)
}

export const snackbarErrorMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case POST_SIGNIN_FAILURE:
    case GET_ALL_LANDING_PAGE_DATA_FAILURE:
    case GET_LANDING_PAGE_BY_ID_FAILURE:
    case CREATE_LANDING_PAGE_DATA_FAILURE:
    case UPDATE_LANDING_PAGE_DATA_FAILURE:
      if(action.payload && action.payload.status === 401)
      {
        store.dispatch({
          type: LOGOUT,
          payload: 'logout'
        })
      } else {
        if(action.payload && action.payload.data){
          store.dispatch({
            type: SHOW_SNACKBAR,
            payload: payloadError(action.payload.data)
          })
        }
      }
      break
    case POST_SIGNIN_FORBIDDEN:
      store.dispatch({
        type: SHOW_SNACKBAR,
        payload: {
          show: true,
          type: 'error',
          message: 'Login ou senha inválidos!'
        }
      })
      break
    case POST_SIGNIN_SUCCESS:
    case POST_SIGNIN_REQUEST:
    case GET_ALL_LANDING_PAGE_DATA_SUCCESS:
    case GET_ALL_LANDING_PAGE_DATA_REQUEST:
    case CREATE_LANDING_PAGE_DATA_SUCCESS:
    case CREATE_LANDING_PAGE_DATA_REQUEST:
    case GET_LANDING_PAGE_BY_ID_SUCCESS:
    case GET_LANDING_PAGE_BY_ID_REQUEST:
    case UPDATE_LANDING_PAGE_DATA_REQUEST:
      store.dispatch({
        type: SHOW_SNACKBAR,
        payload: {
          show: false,
          errorDetails: null
        }
      })
      break
    default:
      break
  }
  next(action)
}

export const snackbarHandleExceptionMiddleware =
  (store) => (next) => (action) => {
    switch (action.type) {
      case 'POST_VALIDATE_TOKEN_FAILURE':
        store.dispatch({
          type: SHOW_SNACKBAR,
          payload: {
            show: true,
            type: 'error',
            message: action.payload
          }
        })
        break
      case 'POST_VALIDATE_TOKEN_REQUEST':
        store.dispatch({
          type: SHOW_SNACKBAR,
          payload: {
            show: false,
            errorDetails: null
          }
        })
        break
      default:
        break
    }
    next(action)
  }

export const snackbarSuccessMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case 'CREATE_LANDING_PAGE_DATA_SUCCESS_1':
      store.dispatch({
        type: SHOW_SNACKBAR,
        payload: {
          show: true,
          type: '',
          message: 'Inclusão realizada com sucesso!'
        }
      })
      break
    case 'UPDATE_USER_SUCCESS':
      store.dispatch({
        type: SHOW_SNACKBAR,
        payload: {
          show: true,
          type: '',
          message: 'Atualização realizada com sucesso!'
        }
      })
      break
    default:
      break
  }
  next(action)
}
