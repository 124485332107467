import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as S from './styled'
import { Typography, Button } from 'components/common'
import { SketchPicker } from 'react-color'

export const ColorPicker = ({ labelText, onChangeColor, hex }) => {
  const [hexSelected, setHexSelected] = useState('#FF6338')
  const [hexSelectedConfirmation, setHexSelectedConfirmation] =
    useState('#FF6338')
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (hex !== hexSelectedConfirmation && hex) {
      setHexSelectedConfirmation(hex)
      setHexSelected(hex)
    }
  }, [hex])

  const handleChangeComplete = (color) => {
    setHexSelected(color.hex)
  }

  const handleConfirmation = () => {
    setShow(false)
    setHexSelectedConfirmation(hexSelected)
    onChangeColor(hexSelected)
  }

  const handleOpenColorPick = () => {
    setHexSelected(hexSelectedConfirmation)
    setShow(true)
  }

  return (
    <>
      <S.Container>
        <Typography tag="h3">{labelText}</Typography>
        <S.BorderBox onClick={handleOpenColorPick}>
          <S.ColorBox hexSelected={hexSelectedConfirmation} />
          <Typography tag="text">Selecione a cor</Typography>
        </S.BorderBox>
      </S.Container>
      {show && (
        <S.ModalWrapper>
          <SketchPicker
            color={hexSelected}
            onChangeComplete={handleChangeComplete}
          />
          <S.Action>
            <Button
              id="download"
              size="sm"
              onClick={() => setShow(false)}
              variant="secondary"
            >
              Cancelar
            </Button>
            <Button id="download" size="sm" onClick={handleConfirmation}>
              Selecionar
            </Button>
          </S.Action>
        </S.ModalWrapper>
      )}
    </>
  )
}

ColorPicker.propTypes = {
  labelText: PropTypes.string.isRequired,
  onChangeColor: PropTypes.func,
  hex: PropTypes.string
}

ColorPicker.defaultProps = {
  onChangeColor: () => {},
  hex: '#FF6338'
}
