import styled, { css } from 'styled-components'

const wrapperModifiers = {
  h1: (theme, variant) => css`
    color: ${variant === 'primary'
      ? theme.colors.primary.base
      : theme.colors.secondary.base};
    font-size: ${theme.fontSize.xl};
  `,
  h2: (theme, variant) => css`
    color: ${variant === 'primary'
      ? theme.colors.primary.base
      : theme.colors.secondary.base};
    font-size: ${theme.fontSize.lg};
  `,
  h3: (theme, variant) => css`
    color: ${variant === 'primary'
      ? theme.colors.gray
      : theme.colors.secondary.base};
    font-size: ${theme.fontSize.lg};
  `,
  h4: (theme, variant) => css`
    color: ${variant === 'primary'
      ? theme.colors.primary.base
      : theme.colors.secondary.base};
    font-size: ${theme.fontSize.base};
    font-weight: 500;
  `,
  h5: (theme, variant) => css`
    color: ${variant === 'primary'
      ? theme.colors.secondary.base
      : theme.colors.primary.base};
    font-size: ${theme.fontSize.md};
  `,

  h6: (theme, variant) => css`
    color: ${theme.colors.primary};
    color: ${variant === 'primary'
      ? theme.colors.primary
      : theme.colors.primary.base};
    font-size: ${theme.fontSize.md};
  `,
  text: (theme, variant) => css`
    color: ${variant === 'primary' ? theme.colors.gray : theme.colors.primary};
    font-size: ${theme.fontSize.base};
    font-weight: ${theme.fontWeight.base};
  `,
  support: (theme, variant) => css`
    color: ${variant === 'primary'
      ? theme.colors.secondary.dark
      : theme.colors.secondary.dark};
    font-size: ${theme.fontSize.md};
    font-weight: ${theme.fontWeight.disclaimer};
  `,
  disclaimer: (theme, variant) => css`
    color: ${variant === 'primary' ? theme.colors.gray : theme.colors.primary};
    font-size: ${theme.fontSize.sm};
    font-weight: ${theme.fontWeight.disclaimer};
  `,
  label: (theme) => css`
    color: ${theme.colors.gray};
    font-size: ${theme.fontSize.md};
    text-transform: uppercase;
  `,
  subtitle: (theme, variant) => css`
    color: ${variant === 'primary'
      ? theme.colors.secondary.dark
      : theme.colors.secondary.light};
    font-size: ${theme.fontSize.xmd};
    font-weight: ${theme.fontWeight.base};
  `,
  default: (theme, variant) => css`
    color: ${variant === 'primary' ? theme.colors.black : theme.colors.black};
    font-size: ${theme.fontSize.default};
    font-weight: ${theme.fontWeight.bold};
  `,
  badge: (theme) => css`
    color: ${theme.colors.white};
    font-size: ${theme.fontSize.smx};
    font-weight: ${theme.fontWeight.bold};
  `,
  heading: () => css``,
  normal: () => css``,
  span: (theme, variant) => css`
    color: ${variant === 'primary'
      ? theme.colors.gray
      : theme.colors.secondary.base};
    font-size: ${theme.fontSize.lg};
  `
}

const tags = ['text', 'support', 'disclaimer', 'default', 'subtitle', 'badge']

export const Wrapper = styled('p').attrs(({ tag }) => ({
  as: `${tags.includes(tag) ? 'p' : tag}`
}))`
  ${({ theme, tag, variant, spacing, align }) => css`
    margin-bottom: ${spacing === '' ? 0 : `${theme.spacing[spacing]}`};
    text-align: ${align};

    ${!tags.includes(tag) && wrapperModifiers.heading(theme)}
    ${tags.includes(tag) && wrapperModifiers.normal(theme)}
    ${!!tag && wrapperModifiers[tag](theme, variant)}
  `}
`
