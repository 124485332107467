import axios from 'axios'
import { getToken } from 'services/auth'
import EnvironmentVariables from '../../environment-variables'

const api = axios.create({
  baseURL: EnvironmentVariables.API_URL,
  headers: {
    'Ocp-Apim-Subscription-Key': EnvironmentVariables.API_SUBSCRIPTION_KEY,
    'Content-Length': 2433
  }
})

api.interceptors.request.use(async (config) => {
  const token = getToken()
  if (token) {
    const configContext = { ...config }
    configContext.headers.Authorization = `Bearer ${token}`
  }
  return config
})

export default api
