/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  Col,
  Grid,
  Row,
  Typography,
  Image,
  Table,
  Badge,
  Button
} from 'components/common'
import { actionGetAllLandingPage, actionDisableLandingPage } from 'redux/actions/LandingPage/Data'
import { strDate } from '../../utils/format/string'
import AddCircle from '../../assets/icons/add-circle.svg'
import Edit from '../../assets/icons/edit.svg'
import Checked from '../../assets/icons/checked.svg'
import Clock from '../../assets/icons/clock.svg'
import Close from '../../assets/icons/close.svg'
import {
  actionSetPartnerData,
  actionSetRegistration
} from '../../redux/actions/Registration/Data'
import * as S from './styled'
import { actionClearLandingPagePersistUpdateData } from 'redux/actions/LandingPage/Data';

const ListPage = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [dataTable, setDataTable] = useState([])
  const [page, setPage] = useState(1)
  const [paginationNumber, setPaginationNumber] = useState(1)

  const {
    data: { payload: landingPageResponse }
  } = useSelector(({ landingPage }) => landingPage)

  useEffect(() => {
    dispatch(actionGetAllLandingPage({ maximoItemPorPagina: 10 }))
  }, [])

  useEffect(() => {
    if (landingPageResponse.landingPages.length > 0) {
      const newDataTable = landingPageResponse.landingPages.map((item) => ({
        id: item.id,
        landingpage: item.nomeParceiro,
        status: item.status,
        usuario: item.trilhaAuditoria[0].usuarioOperacao,
        criado: item.trilhaAuditoria[0].dataOperacao,
        action: '/porto'
      }))

      setDataTable(newDataTable)
    }
  }, [landingPageResponse])

  const handleClear = () => {
    dispatch(actionSetPartnerData({}))
    dispatch(actionSetRegistration({}))
  }


  const handleEdit = (id) => {
    handleClear()
    dispatch(actionClearLandingPagePersistUpdateData())
    history.push(`/project-editor/mainpanel/${id}`)
  }

  const handleDisable = (id) => {
    dispatch(actionDisableLandingPage(id))
  }

  const ladingPageName = (rowData) => (
    <>
      <Image
        alt="Editar"
        src={rowData.status === 'PUBLICADO' ? Checked : Clock}
        style={{
          float: 'left',
          marginRight: 12
        }}
      />
      <span>{rowData.landingpage}</span>
    </>
  )

  const columns = [
    {
      title: 'Landing Page',
      field: 'landingpage',
      align: 'left',
      sorting: false,
      render: (rowData) => ladingPageName(rowData)
    },
    {
      title: 'Status',
      field: 'status',
      align: 'left',
      sorting: false,
      render: (rowData) => (
        <Badge
          text={rowData.status}
          variant={rowData.status === 'PUBLICADO' ? 'success' : (rowData.status === 'DESATIVADO' ? 'desactive' : 'warning') }
        />
      )
    },
    {
      title: 'Usuário',
      field: 'usuario',
      align: 'left',
      sorting: false
    },
    {
      title: 'Criado em',
      field: 'criado',
      align: 'left',
      sorting: false,
      render: (rowData) => (rowData.criado && strDate(rowData.criado)) || '-'
    },
    {
      field: 'action',
      align: 'left',
      sorting: false,
      render: (rowData) => (
        <Button
          id={rowData.id}
          variant="tertiary"
          onClick={() => handleEdit(rowData.id)}
        >
          <Image
            alt="Editar"
            src={Edit && Edit}
            style={{
              float: 'left',
              marginRight: 12
            }}
          />
          Editar
        </Button>
      )
    },
    {
      field: 'action',
      align: 'left',
      sorting: false,
      render: (rowData) => (
        <Button
          id={rowData.id}
          variant="tertiary"
          onClick={() => handleDisable(rowData.id)}
        >
          <Image
            alt="Editar"
            src={Close}
            style={{
              float: 'left',
              marginRight: 12
            }}
          />
          Desativar
        </Button>
      )
    }
  ]
  const handleAddNewPartner = () => {
    handleClear()
    dispatch(
      actionSetRegistration({
        mainPainelPrimaryColor: "#f4460c",
        mainPainelSecondaryColor: "#f4460c",
        benefitsPrimaryColor: "#f4460c",
        benefitsSecondaryColor :"#f4460c",
        offersPrimaryColor: "#f4460c",
        offersSecondaryColor: "#f4460c",
        partnershipPrimaryColor: "#f4460c",
        partnershipSecondaryColor : "#f4460c",
        questPrimaryColor: "#f4460c",
        centralPrimaryColor :"#f4460c",
        centralSecondaryColor :"#f4460c"
      })
    );

    history.push('/project-editor/mainpanel')
  }

  const handleChangePage = (page, paginationNumberSelected) => {
    dispatch(
      actionGetAllLandingPage({
        maximoItemPorPagina: 10,
        pagina: page - 1
      })
    )
    setPage(page)
    setPaginationNumber(paginationNumberSelected)
  }

  return (
    <Grid>
      <Typography tag="h1" variant="secondary" spacing="base">
        Visão Geral
      </Typography>
      <Row>
        <Col xs={12}>
          <S.AddButton onClick={handleAddNewPartner}>
            <Image src={AddCircle} alt="Add new" />
            <Typography tag="h2" style={{ marginLeft: 16 }}>
              Adicionar Landing Page
            </Typography>
          </S.AddButton>
        </Col>
      </Row>
      <Typography tag="h1" variant="secondary">
        Lista de Landing Pages
      </Typography>
      {landingPageResponse.landingPages.length > 0 && (
        <Row>
          <Col>
            <Table
              data={dataTable}
              columns={columns}
              actions={[]}
              totalItems={landingPageResponse.totalDeItens}
              page={page}
              paginationNumber={paginationNumber}
              onChangePage={handleChangePage}
            />
          </Col>
        </Row>
      )}
    </Grid>
  )
}

ListPage.defaultProps = {
  className: null,
  containerSize: '100'
}

export { ListPage }
