import api from 'config/api'

export const getAll = (filters) =>
  api.get('/api/v1/LandingPage', {
    params: { ...filters }
  })

export const getById = (id) => api.get(`/api/v1/LandingPage/${id}`)

export const disable = (id) => api.delete(`/api/v1/LandingPage/${id}`)
export const create = (request) => api.post('/api/v1/LandingPage', request)

export const update = (idLP, request) =>
  api.put(`/api/v1/LandingPage/${idLP}`, request)
