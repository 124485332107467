import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Typography, Tabs } from 'components/common'
import { actionGetPlans } from 'redux/actions/Lists/Plan'
import { actionGetCriterions } from 'redux/actions/Lists/Criterions'
import { actionGetMonthlyFee } from 'redux/actions/Lists/MonthlyFee'
import { actionGetLandingPageById } from 'redux/actions/LandingPage/Data'
import {
  MainPanelPage,
  BenefitsPage,
  OffersPage,
  PartnershipPage,
  QuestionsPage,
  CentralPage,
  ThemePage
} from 'pages'

export const ProjectEditorPage = () => {
  const dispatch = useDispatch()
  const { tab, id } = useParams()
  const history = useHistory()

  const {
    criterions: { payload: criterionsList },
    plans: { payload: plansList },
    monthlyFee: { payload: monthlyFeeList }
  } = useSelector(({ lists }) => lists)

  const [itemSelected, setItemSelected] = useState('mainpanel')

  useEffect(() => {
    setItemSelected(tab)
    dispatch(actionGetPlans())
    dispatch(actionGetCriterions())
    dispatch(actionGetMonthlyFee())
  }, [])

  useEffect(() => {
    if (id) dispatch(actionGetLandingPageById(id))
  }, [id])

  const handlePage = (historyParam) => {
    history.push(
      (!id && `/project-editor/${historyParam}`) ||
        `/project-editor/${historyParam}/${id}`
    )
    setItemSelected(historyParam)
  }
  
  

  const pageLink = {
    mainpanel: <MainPanelPage  onNextPage={() => handlePage('benefits')} />,
    benefits: <BenefitsPage onPreviousPage={() => handlePage('mainpanel')} onNextPage={() => handlePage('offers')} />,
    offers: (
      <OffersPage
        onPreviousPage={() => handlePage('benefits')}
        onNextPage={() => handlePage('partnership')}
        criterionsList={criterionsList}
        plansList={plansList}
        monthlyFeeList={monthlyFeeList}
      />
    ),
    partnership: (
      <PartnershipPage onPreviousPage={() => handlePage('offers')} onNextPage={() => handlePage('questions')} />
    ),
    questions: <QuestionsPage onPreviousPage={() => handlePage('partnership')} onNextPage={() => handlePage('central')} />,
    central: <CentralPage onPreviousPage={() => handlePage('questions')} onNextPage={() => handlePage('theme')} />,
    theme: <ThemePage onPreviousPage={() => handlePage('central')} />
  }

  return (
    <>
      <Typography tag="h1" variant="secondary">
        Editor de projeto
      </Typography>
      <Tabs
        /*onChange={(value) => {
          setItemSelected(value)
          history.push(
            (!id && `/project-editor/${value}`) ||
              `/project-editor/${value}/${id}`
          )
        }}*/
        tab={itemSelected}
      >
        {pageLink[itemSelected]}
      </Tabs>
    </>
  )
}
