import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from 'components/common'

import * as S from './styled'

export const Badge = ({ text, variant }) => {
  return (
    <S.BadgeWrapper variant={variant}>
      <Typography tag="badge">{text.toUpperCase()}</Typography>
    </S.BadgeWrapper>
  )
}

Badge.propTypes = {
  text: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['success', 'waring'])
}

Badge.defaultProps = {
  variant: 'success'
}
