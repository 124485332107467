import { SHOW_SNACKBAR } from 'redux/constants/Dialogs/Snackbar'

const initialState = {
  payload: {
    show: false,
    type: '',
    message: 'success'
  }
}

const Snackbar = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_SNACKBAR:
      return {
        ...state,
        payload: action.payload
      }
    default:
      return state
  }
}

export default Snackbar
