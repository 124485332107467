import * as Yup from 'yup'
import ValidationRules from '../validations/validationsSchemas'

export const offerItemSchema = Yup.object().shape({
  planOffers: ValidationRules.Required(),
  planPriceOffers: ValidationRules.Required(),
  kindOfCriterionOffers: ValidationRules.Required(),
  criterionOffers: ValidationRules.Required(),
  // monthlyTaxOffers: ValidationRules.Required(),
  // monthsExemptionOffers: ValidationRules.Required(),
  priorityOffers: ValidationRules.Required(),
  tagOffers: ValidationRules.Required(),
  supportTextOfferAdd: ValidationRules.Required(),
  textOffers: ValidationRules.Required(),
  startOffers: ValidationRules.StartOfferDate(),
  endOffers: ValidationRules.EndOfferDate()
})
