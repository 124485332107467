import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Col, Row } from 'components/common'
import * as S from './styled'

export const ShowItemOfferPJ = ({ data }) => {
  return (
    <Grid>
      <Row spacing="md">
        <Col xs={12} sm={6}>
          <S.Label>Plano:</S.Label>
          <S.Value>{data.planOffers || '-'}</S.Value>
        </Col>
        <Col xs={12} sm={6}>
          <S.Label>Preço do plano:</S.Label>
          <S.Value>{data.planPriceOffers || '-'}</S.Value>
        </Col>
      </Row>
      <Row spacing="md">
        <Col xs={12} sm={4}>
          <S.Label>TAG:</S.Label>
          <S.Value>{data.tagOffers || '-'}</S.Value>
        </Col>
        <Col xs={12} sm={4}>
          <S.Label>Texto de suporte:</S.Label>
          <S.Value>{data.supportTextOfferAdd || '-'}</S.Value>
        </Col>
      </Row>
      <Row spacing="md">
        <Col xs={12}>
          <S.Label>Texto de apoio</S.Label>
          <S.Value>{data.textOffers || '-'}</S.Value>
        </Col>
      </Row>
      <Row spacing="md">
        <Col xs={12}>
          <S.Label>Habilitar este card como destaque nas opções:</S.Label>
          <S.Value>{data.enableHighlight ? <>&#9989;</> : ''}</S.Value>
        </Col>
      </Row>
    </Grid>
  )
}

ShowItemOfferPJ.propTypes = {
  data: PropTypes.shape({
    planOffers: PropTypes.string,
    textOffers: PropTypes.string,
    enableHighlight: PropTypes.bool,
    tagOffers: PropTypes.string,
    supportTextOfferAdd: PropTypes.string,
    planPriceOffers: PropTypes.string
  }).isRequired
}
