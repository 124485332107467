import {
  SET_REGISTRATION_DATA,
  SET_REGISTRATION_PARTNER_DATA
} from 'redux/constants/Registration/Data'

const setRegistration = (payload) => ({
  type: SET_REGISTRATION_DATA,
  payload
})

export const actionSetRegistration = (payload) => (dispatch) =>
  dispatch(setRegistration(payload))

const setPartnerData = (payload) => ({
  type: SET_REGISTRATION_PARTNER_DATA,
  payload
})

export const actionSetPartnerData = (payload) => (dispatch) =>
  dispatch(setPartnerData(payload))
