import { combineReducers } from 'redux'

import plans from './Plans'
import criterions from './Criterions'
import monthlyFee from './MonthlyFee'

export default combineReducers({
  plans,
  criterions,
  monthlyFee
})
