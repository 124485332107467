import styled from 'styled-components'
import PropTypes from 'prop-types'

export const Divider = styled.div`
  background: #ededed;
  height: 1px;
  margin-bottom: ${({ theme, spacing }) =>
    spacing === '' ? 0 : `${theme.spacing[spacing]}`};
  margin-top: ${({ theme, top }) => (top === '' ? 0 : `${theme.spacing[top]}`)};
  width: 100%;
`

Divider.propTypes = {
  spacing: PropTypes.oneOf([
    '',
    'min',
    'xs',
    'smx',
    'sm',
    'sml',
    'base',
    'mdx',
    'md',
    'mdl',
    'lgx',
    'lg',
    'lgl',
    'max'
  ]),
  top: PropTypes.oneOf([
    '',
    'min',
    'xs',
    'smx',
    'sm',
    'sml',
    'base',
    'mdx',
    'md',
    'mdl',
    'lgx',
    'lg',
    'lgl',
    'max'
  ])
}

Divider.defaultProps = {
  spacing: '',
  top: ''
}
