import {
  GET_PLANS_FAILURE,
  GET_PLANS_REQUEST,
  GET_PLANS_SUCCESS
} from 'redux/constants/Lists/Plan'
import { getPlans } from 'config/api/Lists'

// GET ALL
const plansRequest = () => ({
  type: GET_PLANS_REQUEST
})

const plansSuccess = (response) => ({
  type: GET_PLANS_SUCCESS,
  payload: response
})
const plansFailure = (payload) => ({
  type: GET_PLANS_FAILURE,
  payload
})

const getAllPlans = (dispatch) => {
  dispatch(plansRequest())
  getPlans()
    .then((response) => {
      dispatch(plansSuccess(response.data))
    })
    .catch((error) => {
      dispatch(plansFailure(error.response))
    })
}

export const actionGetPlans = () => (dispatch) => getAllPlans(dispatch)
