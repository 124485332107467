/* eslint-disable func-names */
import * as Yup from 'yup'
import moment from 'moment'
import { findIndex } from 'lodash'
import { isEmail } from './email'
import { validPassword } from './password'
import fullNameRegex from './fullNameRegex'
import onlyLetters from './onlyLetters'
import noSpaceAtFirst from './noSpaceAtFirst'
import { validCpf, isInputCpf } from './cpf'
import { validCNPJ } from './cnpj'

import {
  isDate,
  isPastDate,
  minDateForBirthDate,
  isDateTime,
  isAfterMore2minutesDateTime,
  isAfterMore1minutesDateTime
} from './date'
import { currencyToDouble, clearMask } from '../format/string'

let checked = false

export default {
  Password() {
    return Yup.string()
      .required('Campo obrigatório!')
      .test(
        'password',
        'Senha não atende os critérios de segurança',
        (value) => validPassword(value) && value.length >= 8
      )
  },
  NewPassword() {
    return Yup.string()
      .required('Para sua segurança, precisamos que você crie uma senha.')
      .min(8, 'Mínimo de 8 caracteres!')
  },
  Login() {
    return Yup.string()
      .required('Campo obrigatório!')
      .test('cpf', 'Cpf inválido', (value) => {
        if (isInputCpf(value)) {
          return validCpf(value)
        }
        return true
      })
      .test('email', 'E-mail inválido', (value) => {
        if (!isInputCpf(value)) {
          return isEmail(value)
        }
        return true
      })
  },
  Cpf() {
    return Yup.string()
      .required('Campo obrigatório!')
      .test('cpf', 'Cpf inválido!', (value) => {
        if (value) {
          return validCpf(value)
        }
        return true
      })
  },
  Cnpj() {
    return Yup.string()
      .required('Campo obrigatório!')
      .test('Cnpj', 'Cnpj inválido!', (value) => validCNPJ(value))
  },
  Occupation() {
    return Yup.string()
      .ensure()
      .required('Precisamos que informe a sua ocupação.')
  },
  Profession() {
    return Yup.string().ensure().required('Profissão é obrigatório!')
  },
  Product() {
    return Yup.string().ensure().required('Produto é obrigatório!')
  },
  Email() {
    return Yup.string()
      .required('E-mail é obrigatório!')
      .test('email', 'E-mail inválido!', (value) => isEmail(value))
  },
  Score() {
    return Yup.string().test(
      'score',
      'O Score precisa estar entre 0 e 1000!',
      (value) => {
        if (value) {
          const valueCompare = parseInt(value, 10)
          return valueCompare >= 0 && valueCompare <= 1000
        }
        return true
      }
    )
  },
  FullName() {
    return Yup.string()
      .required('Precisamos do seu nome para continuar com a contratação.')
      .matches(fullNameRegex, 'Digite o nome completo, exemplo: José Silva!')
  },
  OnlyLettersRequiredNotSpaceFirst() {
    return Yup.string()
      .required('Campo obrigatório!')
      .matches(onlyLetters, 'Digite apenas letras!')
      .matches(noSpaceAtFirst, 'Não pode iniciar com valor em branco!')
  },
  RequiredNotSpaceFirst() {
    return Yup.string()
      .required('Campo obrigatório!')
      .matches(noSpaceAtFirst, 'Não pode iniciar com valor em branco!')
  },
  CellPhone() {
    return Yup.string()
      .required(
        'Precisamos do número do seu celular para continuar com a contratação.'
      )
      .test('len', 'Celular inválido!', (cel) => {
        if (cel) {
          const celClear = clearMask(cel)
          const test = celClear.length >= 11
          return test
        }
        return false
      })
  },
  DateNotFuture() {
    return Yup.string()
      .required('Campo obrigatório!')
      .test('validDate', 'Essa data não é compatível.', (value) => {
        if (value) {
          return (
            isDate(value) && isPastDate(value) && minDateForBirthDate(value)
          )
        }
        return true
      })
  },
  Date() {
    return Yup.string()
      .required('Campo obrigatório!')
      .test('validDate', 'Essa data não é compatível.', (value) => {
        if (value) {
          return isDate(value)
        }
        return true
      })
  },
  DateWithDependence(id, key, listId) {
    return Yup.mixed()
      .test('match', 'Campo obrigatório!', function () {
        const parent = this.parent
        const idx = findIndex(parent[listId], ['id', id])
        if (idx === -1) return true
        else {
          if (parent[key]) return true
          return false
        }
      })
      .test('validDate', 'Essa data não é compatível.', (value) => {
        if (value) {
          return isDate(value)
        }
        return true
      })
  },
  DateTime() {
    return Yup.string()
      .required('Campo obrigatório!')
      .test('validDate', 'Data hora inválida!', (value) => {
        if (value) {
          return isDateTime(value)
        }
        return true
      })
  },
  DateTimeGreaterStart1() {
    return Yup.string()
      .required('Campo obrigatório!')
      .test('validDate', 'Data hora inválida!', (value) => {
        const { startPeriod } = this.parent
        if (value) {
          if (value.length === 16)
            return (
              isDateTime(value) &&
              isAfterMore1minutesDateTime(value, startPeriod)
            )
        }
        return true
      })
  },
  DateTimeGreaterStart() {
    return Yup.mixed()
      .required('Campo obrigatório!')
      .test('validDate', 'Data hora inválida!', (value) => {
        if (value) {
          return isDateTime(value)
        }
        return true
      })
      .test(
        'match',
        'Vigência final deve ter pelo menos 1 hora a mais que inicial!',
        function () {
          const { startPeriod, endPeriod } = this.parent
          if (isDateTime(startPeriod) && isDateTime(endPeriod)) {
            return isAfterMore1minutesDateTime(startPeriod, endPeriod)
          }
          return true
        }
      )
  },

  DateFuture() {
    return Yup.string()
      .required('Campo obrigatório!')
      .test('validDate', 'Essa data não é compatível.', (value) => {
        if (value) {
          return isDate(value) && !isPastDate(value)
        }
        return true
      })
  },
  DateTimeNotPast() {
    return Yup.string()
      .required('Campo obrigatório!')
      .test('validDate', 'Data hora inválida.', (value) => {
        if (value) {
          if (value.length === 16) return isDateTime(value)
        }
        return true
      })
      .test('validDate', 'Data hora deve ser maior que a atual.', (value) => {
        if (value) {
          if (value.length === 16) return isAfterMore2minutesDateTime(value)
        }
        return true
      })
      .test('validDate', 'Digite a data e a hora.', (value) => {
        if (value) if (value.length > 0 && value.length < 16) return false
        return true
      })
  },
  BirthDateDateNotFuture() {
    return Yup.string()
      .required('Campo obrigatório!')
      .test('validDate', 'Essa data não é compatível.', (value) => {
        if (value) {
          return (
            isDate(value) && isPastDate(value) && minDateForBirthDate(value)
          )
        }
        return true
      })
  },
  BirthDate() {
    return Yup.string()
      .required('Campo obrigatório!')
      .test('validDate', 'Data inválida!', (value) => {
        if (value) {
          return (
            isDate(value) && isPastDate(value) && minDateForBirthDate(value)
          )
        }
        return true
      })
  },
  PasswordMatch() {
    return Yup.string()
      .required('Campo obrigatório!')
      .oneOf([Yup.ref('password'), null], 'As senhas não conferem!')
  },
  Cep() {
    return Yup.string()
      .required('Campo obrigatório.')
      .test('validCep', 'Cep inválido!', (value) => {
        if (value) {
          const cepNoMask = value.replace('-', '')
          return cepNoMask.length === 8
        }
        return false
      })
  },
  Required() {
    return Yup.string().required('Campo obrigatório!')
  },
  RequiredWithDependence(id, key, listId) {
    return Yup.mixed().test('match', 'Campo obrigatório!', function () {
      const parent = this.parent
      const idx = findIndex(parent[listId], ['id', id])
      if (idx === -1) return true
      else {
        if (parent[key]) return true
        return false
      }
    })
  },
  RequiredNullable() {
    return Yup.string().required('Campo obrigatório!').nullable()
  },
  RequiredNullableWithParam(param) {
    return Yup.string().required(`Precisamos que informe ${param}`).nullable()
  },

  RequiredWithParam(param) {
    return Yup.string().required(`Precisamos que informe ${param}`)
  },
  EndOfferDateWithDependence(id, key, listId, startOffer) {
    return Yup.mixed()
      .test('match', 'Campo obrigatório!', function () {
        const parent = this.parent
        const idx = findIndex(parent[listId], ['id', id])
        if (idx === -1) return true
        else {
          if (parent[key]) return true
          return false
        }
      })
      .test('validDate', 'Essa data não é compatível.', (value) => {
        if (value) {
          return isDate(value)
        }
        return true
      })
      .test(
        'match',
        'Fim da oferta menor que o Início da oferta!',
        function () {
          const parent = this.parent
          if (isDate(parent[startOffer]) && isDate(parent[key])) {
            const end = moment(parent[key], 'DD/MM/YYYY')
            const start = moment(parent[startOffer], 'DD/MM/YYYY')
            return end.isSameOrAfter(start)
          }

          return true
        }
      )
  },
  StartOfferDate() {
    return Yup.string()
      .required('Campo obrigatório!')
      .test('validDate', 'Essa data não é compatível.', (value) => {
        if (value) {
          return (
            isDate(value) && !isPastDate(value) && minDateForBirthDate(value)
          )
        }
        return true
      })
  },
  EndOfferDate() {
    return Yup.mixed()
      .required('Campo obrigatório!')
      .test('validDate', 'Essa data não é compatível.', (value) => {
        if (value) {
          return isDate(value) && !isPastDate(value)
        }
        return true
      })
      .test(
        'match',
        'Fim da oferta menor que o Início da oferta!',
        function () {
          const { startOffers, endOffers } = this.parent

          if (isDate(startOffers) && isDate(endOffers)) {
            const end   = moment(endOffers, 'DD/MM/YYYY')
            const start = moment(startOffers, 'DD/MM/YYYY')
            return end.isSameOrAfter(start)
          }

          return true
        }
      )
  },
  EndPeriod() {
    return Yup.mixed()
      .required('Campo obrigatório!')
      .test('validDate', 'Essa data não é compatível.', (value) => {
        if (value) {
          return isDate(value) && !isPastDate(value)
        }
        return true
      })
      .test(
        'match',
        'Vigência final menor que o vigência inicial!',
        function () {
          const { endPeriod, startPeriod } = this.parent
          if (isDate(endPeriod) && isDate(startPeriod)) {
            const end = moment(endPeriod, 'DD/MM/YYYY')
            const start = moment(startPeriod, 'DD/MM/YYYY')
            return end.isSameOrAfter(start)
          }

          return true
        }
      )
  },
  AddressNumber() {
    return Yup.string().test(
      'validAddressNumber',
      'Precisamos do número da sua residência.',
      (value) => {
        if (checked || value) {
          return true
        }
        return false
      }
    )
  },
  MonthlyIncome() {
    return Yup.string()
      .required('Campo obrigatório!')
      .test(
        'monthlyIncome',
        'Renda mensal acima do límite máximo R$: 100.000,00 !',
        (value) => {
          if (value) {
            return currencyToDouble(value) <= 100000
          }
          return true
        }
      )
      .test(
        'monthlyIncome',
        'Renda mensal não pode ser 0 ou negativo !',
        (value) => {
          if (value) {
            return currencyToDouble(value) > 0
          }
          return true
        }
      )
  },
  CheckNoNumber() {
    return Yup.string().test('checkNoNumber', (value) => {
      checked = value === 'true'
      return true
    })
  },
  ExtraFieldRequiredServiceSimplified() {
    return Yup.string().when('service', {
      is: (service) => service === 'SIMPLIFIED_ECOMMERCE',
      then: Yup.string().required('Campo obrigatório!')
    })
  },
  ExtraFieldRequiredServiceDefault() {
    return Yup.string().when('service', {
      is: (service) => service === 'DEFAULT_ECOMMERCE',
      then: Yup.string().required('Campo obrigatório!')
    })
  },
  ApiKey() {
    return Yup.string().when('credentials_per_retailer', {
      is: (credentials) => credentials,
      then: Yup.string().required('Campo obrigatório!')
    })
  },
  ApiServiceDefault() {
    return Yup.string().when('service', {
      is: (service) => service === 'DEFAULT_ECOMMERCE',
      then: Yup.string().required('Campo obrigatório!')
    })
  }
}
