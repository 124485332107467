import styled, { css } from 'styled-components'

const radioVariants = {
  primary: () => css`
    border: 1px solid ${({ theme }) => theme.colors.text.tertiary};
    color: ${({ theme }) => theme.colors.gray};
  `,

  selected: () => css`
    border: 1px solid ${({ theme }) => theme.colors.primary.base};
    color: ${({ theme }) => theme.colors.primary.base};
  `
}

export const Radio = styled.div`
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex: 1;
  font-size: ${({ theme }) => theme.fontSize.base};
  height: 63px;
  min-width: 228px;
  padding-left: ${({ theme }) => theme.spacing.md};
  user-select: none;

  ${(props) => radioVariants[props.variant]}

  span {
    margin-left: ${({ theme }) => theme.spacing.sml};
  }
`

export const WrapperRadioGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${({ theme, spacing }) =>
    spacing === '' ? 0 : `${theme.spacing[spacing]}`};

  ${Radio}:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing.lgl};
  }
`

const radioCircleVariants = {
  primary: () => css`
    border: 2px solid ${({ theme }) => theme.colors.text.tertiary};
  `,

  selected: () => css`
    background-color: ${({ theme }) => theme.colors.primary.base};
    border: none;
  `
}

export const RadioCircle = styled.div`
  align-items: center;
  border-radius: 50%;
  display: flex;
  ${(props) => radioCircleVariants[props.variant]}
  height: 16px;
  justify-content: center;
  margin-right: ${({ theme }) => theme.spacing.sml};
  width: 16px;
`
