import styled, { css } from 'styled-components'

export const Container = styled.td`
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  align-items: center;
  display: flex;
  flex-direction: column-reverse;
  height: 32px;
  margin-bottom: 57px;
  margin-top: ${({ theme }) => theme.spacing.base};
  width: 100%;
`

export const BoxActions = styled.div`
  align-items: center;
  display: flex;
  padding: ${({ theme }) => theme.spacing.xs};
  ${({ theme }) => theme.spacing.smx};

`


const variants = {
  primary: () => css`
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid #DFE3E8;
    cursor: pointer;
  `,

  secondary: () => css`
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid #DFE3E8;
    color: #C4CDD5;
    cursor: pointer;
  `,

  selected: () => css`
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.primary.base};
    color: ${({ theme }) => theme.colors.primary.base};
    cursor: pointer;
  `,

  disabled: () => css`
    background-color: #919EAB;
    border: none;
    color: #C4CDD5;
    cursor: default;
  `}

export const PaginationButtons = styled.div`
  align-items: center;
  border-radius: 5px;
  display: flex;
  font-size: ${({ theme }) => theme.fontSize.sm};
  height: 32px;
  justify-content: center;
  margin-right: ${({theme}) => theme.spacing.sm};
  width: 32px;
  ${(props) => variants[props.variant]}
`
