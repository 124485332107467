import * as Yup from 'yup'
import ValidationRules from '../validations/validationsSchemas'

export const benefitsSchema = Yup.object().shape({
  titleBenefits: ValidationRules.Required(),
  supportTextBenefits: ValidationRules.Required(),
  uploadImageBenefits: ValidationRules.Required(),
  btnTextBenefits1: ValidationRules.RequiredWithDependence(
    1,
    'btnTextBenefits1',
    'benefitsListId'
  ),
  btnTextBenefits2: ValidationRules.RequiredWithDependence(
    2,
    'btnTextBenefits2',
    'benefitsListId'
  ),
  btnTextBenefits3: ValidationRules.RequiredWithDependence(
    3,
    'btnTextBenefits3',
    'benefitsListId'
  ),
  btnTextBenefits4: ValidationRules.RequiredWithDependence(
    4,
    'btnTextBenefits4',
    'benefitsListId'
  ),
  btnTextBenefits5: ValidationRules.RequiredWithDependence(
    5,
    'btnTextBenefits5',
    'benefitsListId'
  ),
  btnTextBenefits6: ValidationRules.RequiredWithDependence(
    6,
    'btnTextBenefits6',
    'benefitsListId'
  )
})
