import * as Yup from 'yup'
import ValidationRules from '../validations/validationsSchemas'

export const themeSchema = Yup.object().shape({
  dnsTheme: ValidationRules.Required(),
  titleTheme: ValidationRules.Required(),
  descriptionTheme: ValidationRules.Required(),
  legalTextTheme: ValidationRules.Required(),
  uploadImageTheme: ValidationRules.Required(),
  uploadTerm: ValidationRules.Required(),
  uploadPdf: ValidationRules.Required()
})
