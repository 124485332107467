import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import * as S from './styled.js'

const CustomPagination = ({
  totalItems,
  page,
  paginationNumber,
  handleChangePage
}) => {
  const [paginationCount, setPaginationCount] = useState([])
  const [paginationSplit, setPaginationSplit] = useState({})
  const [paginationSplitSelected, setPaginationSplitSelected] = useState(1)
  const [selected, setSelected] = useState(1)

  useEffect(() => {
    if (totalItems) {
      let newPaginationCount = []
      const newPaginationSplit = {}
      let splitCont = 1
      let totalPage = 1
      totalPage = parseInt(totalItems / 10, 10)
      if (totalItems % 10 !== 0) totalPage += 1
      for (let i = 1; i <= totalPage; i++) {
        newPaginationCount.push(i)
        if (totalPage < 10) {
          newPaginationSplit[splitCont] = newPaginationCount
        } else if (i / 10 === splitCont) {
          newPaginationSplit[splitCont] = newPaginationCount
          splitCont += 1
          newPaginationCount = []
        }
      }
      setPaginationSplit(newPaginationSplit)
      setPaginationCount(newPaginationSplit[paginationSplitSelected])
    }
  }, [totalItems, page, paginationSplitSelected])

  useEffect(() => {
    if (page !== selected) {
      setSelected(page)
    }
  }, [page])

  useEffect(() => {
    if (paginationNumber !== paginationSplitSelected) {
      setPaginationSplitSelected(paginationNumber)
    }
  }, [paginationNumber])

  const handleClickPagination = (value) => {
    setSelected(value)
    handleChangePage(value, paginationSplitSelected)
  }

  const nextPreviewClick = (type) => {
    if (
      type === 'NEXT' &&
      paginationSplitSelected < Object.keys(paginationSplit).length
    ) {
      setPaginationCount(paginationSplit[paginationSplitSelected + 1])
      setPaginationSplitSelected(paginationSplitSelected + 1)
    } else if (type === 'PREVIEW' && paginationSplitSelected !== 1) {
      setPaginationCount(paginationSplit[paginationSplitSelected - 1])
      setPaginationSplitSelected(paginationSplitSelected - 1)
    }
  }

  return (
    <S.Container>
      <S.BoxActions>
        <S.PaginationButtons
          variant={paginationSplitSelected === 1 ? 'disabled' : 'secondary'}
          onClick={() => nextPreviewClick('PREVIEW')}
        >
          &lt;
        </S.PaginationButtons>

        {paginationCount.map((item) => (
          <S.PaginationButtons
            key={item}
            id={`btn_page_${item}`}
            onClick={() => handleClickPagination(item)}
            variant={selected === item ? 'selected' : 'primary'}
          >
            {item}
          </S.PaginationButtons>
        ))}

        <S.PaginationButtons
          variant={
            paginationSplitSelected === Object.keys(paginationSplit).length
              ? 'disabled'
              : 'secondary'
          }
          onClick={() => nextPreviewClick('NEXT')}
        >
          &gt;
        </S.PaginationButtons>
      </S.BoxActions>
    </S.Container>
  )
}

CustomPagination.propTypes = {
  totalItems: PropTypes.number,
  page: PropTypes.number,
  paginationNumber: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired
}

CustomPagination.defaultProps = {
  totalItems: 7,
  page: 1
}
export default CustomPagination
