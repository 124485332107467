import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import {
  Typography,
  Button,
  TextInput,
  FileUpload,
  ColorPicker,
  TextareaInput,
  CardContainer,
  Grid,
  Col,
  Row,
  Form
} from 'components/common'
import { partnershipSchema } from 'utils/schemas'
import { useRegistration } from 'hooks'
import { actionSetRegistration } from 'redux/actions/Registration/Data'

export const PartnershipPage = ({ onPreviousPage, onNextPage }) => {
  const registration = useRegistration()
  const dispatch = useDispatch()

  const {
    partnerData: { payload },
    data: { payload: dataFormGlobalStore }
  } = useSelector(({ registration }) => registration)
  const [fillForm, setFillForm] = useState(false)

  useEffect(() => {    
    if (!isEmpty(payload) && !fillForm) {
      formik.setFieldValue(
        'titlePartnership',
        dataFormGlobalStore.titlePartnership || payload.titlePartnership
      )
      formik.setFieldValue(
        'supportTextPartnership',
        dataFormGlobalStore.supportTextPartnership ||
        payload.supportTextPartnership
      )
      formik.setFieldValue(
        'uploadImagePartnership',
        dataFormGlobalStore.uploadImagePartnership ||
        payload.uploadImagePartnership
      )
      formik.setFieldValue(
        'partnershipPrimaryColor',
        dataFormGlobalStore.partnershipPrimaryColor ||
        payload.partnershipPrimaryColor
      )
      formik.setFieldValue(
        'partnershipSecondaryColor',
        dataFormGlobalStore.partnershipSecondaryColor ||
        payload.partnershipSecondaryColor
      )

      dispatch(
        actionSetRegistration({
          ...dataFormGlobalStore,
          titlePartnership:
            dataFormGlobalStore.titlePartnership || payload.titlePartnership,
          supportTextPartnership:
            dataFormGlobalStore.supportTextPartnership ||
            payload.supportTextPartnership,
          uploadImagePartnership:
            dataFormGlobalStore.uploadImagePartnership ||
            payload.uploadImagePartnership,
          partnershipPrimaryColor:
            dataFormGlobalStore.partnershipPrimaryColor ||
            payload.partnershipPrimaryColor,
          partnershipSecondaryColor:
            dataFormGlobalStore.partnershipSecondaryColor ||
            payload.partnershipSecondaryColor
        })
      )
      
      setFillForm(true)
    }
  }, [payload, dataFormGlobalStore])

  const handleSubmit = () => onNextPage()

  const formik = useFormik({
    initialValues: {
      titlePartnership: dataFormGlobalStore.titlePartnership || '',
      supportTextPartnership: dataFormGlobalStore.supportTextPartnership || '',
      uploadImagePartnership: dataFormGlobalStore.uploadImagePartnership || '',
      partnershipPrimaryColor:
        dataFormGlobalStore.partnershipPrimaryColor || '',
      partnershipSecondaryColor:
        dataFormGlobalStore.partnershipSecondaryColor || ''
    },
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: handleSubmit,
    validationSchema: () => partnershipSchema
  })

  return (
    <Grid>
      <Form onSubmit={formik.handleSubmit}>
        <Row>
          <Col xs={12} sm={8}>
            <CardContainer>
              <Typography tag="h2" variant="secondary" spacing="smx">
                Preencha as informações do bloco
              </Typography>
              <Typography tag="disclaimer" spacing="mdx">
                * Campo de preenchimento obrigatório
              </Typography>
              <Row spacing="lg">
                <Col xs={12} sm={6}>
                  <ColorPicker
                    key="principal"
                    labelText="Cor principal do bloco"
                    hex={formik.values.partnershipPrimaryColor}
                    onChangeColor={(value) =>
                      registration.setProp(
                        'partnershipPrimaryColor',
                        value,
                        formik
                      )
                    }
                  />
                </Col>
                <Col xs={12} sm={6}>
                  <ColorPicker
                    key="principal"
                    labelText="Cor secundária do bloco"
                    hex={formik.values.partnershipSecondaryColor}
                    onChangeColor={(value) =>
                      registration.setProp(
                        'partnershipSecondaryColor',
                        value,
                        formik
                      )
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <TextInput
                    id="titlePartnership"
                    label="Título do bloco"
                    type="text"
                    name="titlePartnership"
                    value={formik.values.titlePartnership}
                    onChange={(event) => registration.onChange(event, formik)}
                    onBlur={formik.handleBlur}
                    errorMessage={
                      (formik.touched.titlePartnership &&
                        formik.errors.titlePartnership) ||
                      ''
                    }
                    placeholder="Ex: Sobre a parceria e ofertas"
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <TextareaInput
                    id="supportTextPartnership"
                    label="Texto de apoio"
                    name="supportTextPartnership"
                    value={formik.values.supportTextPartnership}
                    onChange={(event) => registration.onChange(event, formik)}
                    onBlur={formik.handleBlur}
                    errorMessage={
                      (formik.touched.supportTextPartnership &&
                        formik.errors.supportTextPartnership) ||
                      ''
                    }
                    placeholder="Digite o texto de descrição"
                  />
                </Col>
              </Row>
              <Row direction="row-reverse">
                <Col md={6}>
                  <Row direction="row-reverse">
                    <Button id="btn_save_account" type="submit">
                      Continuar
                    </Button>
                  </Row>
                </Col>
                <Col md={6}>
                  <Button id="btn_voltar" type="button" onClick={() => onPreviousPage()}>
                    Voltar
                  </Button>
                </Col>
              </Row>
            </CardContainer>
          </Col>
          <Col xs={12} sm={3}>
            <CardContainer>
              <FileUpload
                id="uploadImagePartnership"
                name="uploadImagePartnership"
                value={formik.values.uploadImagePartnership}
                onClick={() => {
                  formik.setFieldError(
                    'uploadImagePartnership',
                    'Campo obrigatório'
                  )
                  formik.setFieldTouched('uploadImagePartnership', true, false)
                }}
                onChange={(value) => {
                  registration.setProp('uploadImagePartnership', value, formik)
                }}
                onBlur={formik.handleBlur}
                errorMessage={
                  (formik.touched.uploadImagePartnership &&
                    formik.errors.uploadImagePartnership) ||
                  ''
                }
              />
            </CardContainer>
          </Col>
        </Row>
      </Form>
    </Grid>
  )
}

PartnershipPage.propTypes = {
  onNextPage: PropTypes.func.isRequired
}
