import React from 'react'
import PropTypes from 'prop-types'
import { Image } from 'components/common'
import CheckboxEmpty from 'assets/icons/checkbox-empty.svg'
import Checkbox from 'assets/icons/checkbox.svg'

import * as S from './styled'

export const CheckBox = ({ spacing, selected, onSelected, checkboxProps }) => {
  return (
    <S.WrapperCheckBox spacing={spacing}>
      <S.Checkbox
        onClick={onSelected}
        variant={selected ? 'selected' : 'primary'}
      >
        <Image src={selected ? Checkbox : CheckboxEmpty} alt="Checkbox" />
        <span>{checkboxProps.label}</span>
      </S.Checkbox>
    </S.WrapperCheckBox>
  )
}

CheckBox.propTypes = {
  selected: PropTypes.bool.isRequired,
  onSelected: PropTypes.func.isRequired,
  checkboxProps: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string
  }),
  spacing: PropTypes.oneOf([
    '',
    'min',
    'xs',
    'smx',
    'sm',
    'sml',
    'base',
    'mdx',
    'md',
    'mdl',
    'lgx',
    'lg',
    'lgl',
    'max'
  ])
}

CheckBox.defaultProps = {
  spacing: 'sml'
}
