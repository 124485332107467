import styled, { css, keyframes } from 'styled-components'

export const Wrapper = styled.div`
  ${({ show }) => css`
    align-content: center;
    align-items: center;
    display: ${show ? 'flex' : 'none'};
    flex-direction: column;
    height: 100%;
    justify-content: center;
  `}
`

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 auto;
  width: 100%;
`

export const BoxImageInfo = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: ${theme.spacing.base};
    width: 100%;

    svg {
      height: 16rem;
      pointer-events: none;
    }
  `}
`

const placeHolderShimmer = keyframes`
  0% {
    background-position: -468px 0
  }

  100% {
    background-position: 468px 0
  }
`

export const Placeholder = styled.div`
  ${({ theme }) => css`
    animation-duration: 1.75s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${placeHolderShimmer};
    animation-timing-function: linear;
    background: linear-gradient(
      to right,
      #eeeeee 10%,
      #dddddd 20%,
      #eeeeee 10%
    );
    background-color: #dddddd;
    background-size: 800px 104px;
    border-radius: ${theme.radius.md};
    height: 20px;
    margin-bottom: ${theme.spacing.base};
    position: relative;
    width: 100%;

    &.image {
      border-radius: ${theme.radius.md};
      height: 120px;
      margin-bottom: ${theme.spacing.sm};
      width: 100%;
    }

    &.title {
      height: 25px;
      width: 140px;
      margin-bottom: ${theme.spacing.base};
    }

    &.paragraph {
      height: 26px;
      margin-bottom: ${theme.spacing.base};
    }

    &.button {
      height: 54px;
      margin-bottom: ${theme.spacing.base};
    }

    &.input {
      height: 80px;
      margin-bottom: ${theme.spacing.mdx};
    }

    &.card {
      height: 69px;
    }
  `}
`
