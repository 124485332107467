import React from 'react'
import PropTypes from 'prop-types'
import { Image } from 'components/common'
import IconClose from 'assets/icons/close.svg'
import * as S from './styled'

const TextareaInput = ({
  className,
  containerId,
  labelClass,
  label,
  name,
  placeholder,
  disabled,
  value,
  onChange,
  errorMessage,
  onBlur,
  id,
  inputMode,
  size,
  maxLength,
  isCleaner,
  variant,
  onClear,
  linkBottom,
  onlyView,
  radius,
  ...rest
}) => {
  return (
    <S.InputGroupWrapper
      variant={variant}
      className={`${className} ${errorMessage ? 'error' : ''}`}
      id={containerId}
      {...rest}
    >
      <S.InputContainer variant={variant} onlyView={onlyView} radius={radius}>
        <S.Label
          className={`${labelClass} label-input`}
          htmlFor={id}
          variant={variant}
        >
          {label}
        </S.Label>

        <textarea
          className="floating"
          name={name}
          id={id}
          placeholder={placeholder}
          disabled={disabled || onlyView}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          inputMode={inputMode}
          maxLength={maxLength}
          size={size}
          aria-label={name}
        />

        {isCleaner && value && (
          <S.ShowIconClose onClick={onClear}>
            <Image src={IconClose} alt="IconClose" />
          </S.ShowIconClose>
        )}

        {linkBottom && <S.ErrorMessage>{linkBottom}</S.ErrorMessage>}
      </S.InputContainer>

      <S.ErrorMessage>{errorMessage && errorMessage}</S.ErrorMessage>
    </S.InputGroupWrapper>
  )
}

TextareaInput.propTypes = {
  className: PropTypes.string,
  inputMode: PropTypes.string,
  maxLength: PropTypes.string,
  size: PropTypes.string,
  containerId: PropTypes.string,
  variant: PropTypes.string,
  labelClass: PropTypes.string,
  label: PropTypes.node.isRequired,
  name: PropTypes.string,
  onlyView: PropTypes.bool,
  isCleaner: PropTypes.bool,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onClear: PropTypes.func,
  errorMessage: PropTypes.string,
  linkBottom: PropTypes.node,
  radius: PropTypes.oneOf(['sm', 'md', 'lg'])
}

TextareaInput.defaultProps = {
  radius: 'sm',
  className: '',
  inputMode: null,
  maxLength: null,
  isCleaner: false,
  size: null,
  containerId: null,
  variant: 'primary',
  labelClass: '',
  name: null,
  placeholder: ' ',
  linkBottom: null,
  disabled: false,
  value: '',
  id: null,
  onChange: () => {},
  onClear: () => {},
  onBlur: () => {},
  errorMessage: '',
  onlyView: false
}

export { TextareaInput }
