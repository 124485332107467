import styled from 'styled-components'
import breakpoints from 'utils/mediaQueries/media'

export const Container = styled.div`
  input {
    border-radius: 10px !important;
  }
`

export const BoxImage = styled.div`
  img {
    margin-bottom: ${({ theme }) => theme.spacing.sml};
  }
  @media (max-width: ${breakpoints.md}) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: ${({ theme }) => theme.spacing.sml};
  }
`

export const BoxLinkButton = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing.mdl};
  width: 100%;
`

export const BoxAction = styled.div`
  display: flex;
  width: 90%;
`
