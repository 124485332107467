import React from 'react'
import * as S from './styled'

export const Footer = () => {
  return (
    <S.Footer>
      <S.GridContainer>
        Ambiente seguro · ConectCar CNPJ: 16.577.631/0002-99 | © - Todos os
        direitos reservados
      </S.GridContainer>
    </S.Footer>
  )
}
