import styled, { keyframes } from 'styled-components'

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme, spacing }) =>
    spacing === '' ? 0 : `${theme.spacing[spacing]}`};
  padding: ${({ theme }) => theme.spacing.sml};

  img {
    margin-bottom: ${({ theme }) => theme.spacing.sm};
  }
`
export const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.colors.critical.dark};
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-style: italic;
  margin-top: ${({ theme }) => theme.spacing.sm};
  min-height: ${({ theme }) => theme.spacing.sm};
  order: 2;
`

export const RightLeft = keyframes`
 from{
    transform: scale(0);
    opacity:0;
     }
 to{
    transform: scale(1);
    opacity: 1;
     }

`
export const ModalWrapper = styled.div`
  align-items: center;
  animation: ${RightLeft} 0.2s;
  background: rgba(202, 202, 202, 0.3);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 100;
`
export const Action = styled.div`
  align-items: center;
  display: flex;
  margin-top: ${({ theme }) => theme.spacing.sm};

  button:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing.max};
  }
`
