import React, { useState, useEffect } from 'react'
import { findIndex, isEmpty, forEach } from 'lodash'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import {
  Typography,
  Button,
  TextInput,
  FileUpload,
  ColorPicker,
  TextareaInput,
  CardContainer,
  Grid,
  Col,
  Row,
  Image,
  Form
} from 'components/common'
import MinusIcon from 'assets/icons/minus.svg'
import PlusIcon from 'assets/icons/plus.svg'
import { benefitsSchema } from 'utils/schemas'
import { useRegistration } from 'hooks'
import { actionSetRegistration } from 'redux/actions/Registration/Data'

const LIST_COMPLETE_IDS = [1, 2, 3, 4, 5, 6]

export const BenefitsPage = ({ onPreviousPage, onNextPage }) => {
  const dispatch = useDispatch()
  const registration = useRegistration()
  const {
    partnerData: { payload },
    data: { payload: dataFormGlobalStore }
  } = useSelector(({ registration }) => registration)

  const [fillForm, setFillForm] = useState(false)
  const [formList, setFormList] = useState([
    {
      id: 1,
      textName: 'btnTextBenefits1'
    }
  ])

  useEffect(() => {
    if (!isEmpty(payload) && !fillForm) {
      formik.setFieldValue(
        'titleBenefits',
        dataFormGlobalStore.titleBenefits || payload.titleBenefits
      )
      formik.setFieldValue(
        'benefitsPrimaryColor',
        dataFormGlobalStore.benefitsPrimaryColor || payload.benefitsPrimaryColor
      )
      formik.setFieldValue(
        'benefitsSecondaryColor',
        dataFormGlobalStore.benefitsSecondaryColor ||
        payload.benefitsSecondaryColor
      )
      formik.setFieldValue(
        'supportTextBenefits',
        dataFormGlobalStore.supportTextBenefits || payload.supportTextBenefits
      )
      formik.setFieldValue(
        'uploadImageBenefits',
        dataFormGlobalStore.uploadImageBenefits || payload.uploadImageBenefits
      )
      registration.setProp(
        'benefitsListId',
        (dataFormGlobalStore.benefitsListId || payload.formListBenefits).map((_item, idx) => { 
          return {id: idx + 1}
        }),
        formik
      )
      forEach(payload.formListBenefits, (item, idx) => {
          var key = `btnTextBenefits${idx + 1}`;
          var hasValue = dataFormGlobalStore[key];

          if (!hasValue) 
            hasValue = item.nome;

          formik.setFieldValue(key, hasValue);
          registration.setProp(key, hasValue, formik);
        }
      )
      dispatch(
        actionSetRegistration({
          ...dataFormGlobalStore,
          titleBenefits:
            dataFormGlobalStore.titleBenefits || payload.titleBenefits,
          benefitsPrimaryColor:
            dataFormGlobalStore.benefitsPrimaryColor ||
            payload.benefitsPrimaryColor,
          benefitsSecondaryColor:
            dataFormGlobalStore.benefitsSecondaryColor ||
            payload.benefitsSecondaryColor,
          supportTextBenefits:
            dataFormGlobalStore.supportTextBenefits ||
            payload.supportTextBenefits,
          uploadImageBenefits:
            dataFormGlobalStore.uploadImageBenefits ||
            payload.uploadImageBenefits,
          benefitsItemsList:
            dataFormGlobalStore.benefitsItemsList || payload.formListBenefits
        })
      )

      setFillForm(true)

      setFillForm(true)
    }
  }, [payload, dataFormGlobalStore])

  const handleSubmit = () => {
    const benefitsItemsList = []
    forEach(formik.values, (value, key) => {
      if (key.includes('btnTextBenefits')) {
        if (value) {
          benefitsItemsList.push(value)
        }
      }
    })

    registration.setProp('benefitsItemsList', benefitsItemsList, formik)
    
    onNextPage()
  }

  const formik = useFormik({
    initialValues: {
      benefitsPrimaryColor: dataFormGlobalStore.benefitsPrimaryColor || '',
      benefitsSecondaryColor: dataFormGlobalStore.benefitsSecondaryColor || '',
      titleBenefits: dataFormGlobalStore.titleBenefits || '',
      supportTextBenefits: dataFormGlobalStore.supportTextBenefits || '',
      uploadImageBenefits: dataFormGlobalStore.uploadImageBenefits || '',
      btnTextBenefits1: dataFormGlobalStore.btnTextBenefits1 || '',
      btnTextBenefits2: dataFormGlobalStore.btnTextBenefits2 || '',
      btnTextBenefits3: dataFormGlobalStore.btnTextBenefits3 || '',
      btnTextBenefits4: dataFormGlobalStore.btnTextBenefits4 || '',
      btnTextBenefits5: dataFormGlobalStore.btnTextBenefits5 || '',
      btnTextBenefits6: dataFormGlobalStore.btnTextBenefits6 || '',
      benefitsListId: dataFormGlobalStore.benefitsListId || [{ id: 1 }]
    },
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: handleSubmit,
    validationSchema: () => benefitsSchema
  })

  useEffect(() => {
    if (!isEmpty(formik.values.benefitsListId)) {
      const newFormList = []

      forEach(formik.values.benefitsListId, ({ id }) => {
        newFormList.push({
          id: id,
          textName: `btnTextBenefits${id}`
        })
      })

      setFormList(newFormList)
    }
  }, [formik && formik.values.benefitsListId])

  const handleEditTextAndLinkBtnInputList = (operation, id) => {
    const newFormList = [...formList]
    const listIds = formList.map((item) => ({
      id: item.id
    }))
    const newListIds = [...listIds]
    if (operation === 'add') {
      const newId = registration.generateFormId(listIds, LIST_COMPLETE_IDS)
      newFormList.push({
        id: newId,
        textName: `btnTextBenefits${newId}`
      })
      newListIds.push({ id: newId })
      registration.setManyProp(
        {
          [`btnTextBenefits${newId}`]: '',
          benefitsListId: newListIds
        },
        formik
      )
    } else {
      const idx = findIndex(formList, ['id', id])
      if (idx !== -1) {
        newFormList.splice(idx, 1)
        newListIds.splice(idx, 1)
        registration.setManyProp(
          {
            [`btnTextBenefits${id}`]: '',
            benefitsListId: newListIds
          },
          formik
        )
      }
    }
    setFormList(newFormList)
  }

  return (
    <Grid>
      <Form onSubmit={formik.handleSubmit}>
        <Row>
          <Col xs={12} sm={8}>
            <CardContainer>
              <Typography tag="h2" variant="secondary" spacing="smx">
                Preencha as informações do bloco
              </Typography>
              <Typography tag="disclaimer" spacing="mdx">
                * Campo de preenchimento obrigatório
              </Typography>
              <Row spacing="lg">
                <Col xs={12} sm={6}>
                  <ColorPicker
                    key="principal"
                    labelText="Cor principal do bloco"
                    hex={formik.values.benefitsPrimaryColor}
                    onChangeColor={(value) =>
                      registration.setProp(
                        'benefitsPrimaryColor',
                        value,
                        formik
                      )
                    }
                  />
                </Col>
                <Col xs={12} sm={6}>
                  <ColorPicker
                    key="principal"
                    labelText="Cor secundária do bloco"
                    hex={formik.values.benefitsSecondaryColor}
                    onChangeColor={(value) =>
                      registration.setProp(
                        'benefitsSecondaryColor',
                        value,
                        formik
                      )
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <TextInput
                    id="titleBenefits"
                    label="Título do bloco"
                    type="text"
                    name="titleBenefits"
                    value={formik.values.titleBenefits}
                    onChange={(event) => registration.onChange(event, formik)}
                    onBlur={formik.handleBlur}
                    errorMessage={
                      (formik.touched.titleBenefits &&
                        formik.errors.titleBenefits) ||
                      ''
                    }
                    placeholder="Ex: Rede completa de benefícios"
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <TextareaInput
                    id="supportTextBenefits"
                    label="Texto de apoio"
                    name="supportTextBenefits"
                    value={formik.values.supportTextBenefits}
                    onChange={(event) => registration.onChange(event, formik)}
                    onBlur={formik.handleBlur}
                    errorMessage={
                      (formik.touched.supportTextBenefits &&
                        formik.errors.supportTextBenefits) ||
                      ''
                    }
                    placeholder="Digito o texto"
                  />
                </Col>
              </Row>
              {formList.map((item, idx) => (
                <Row align="center" gutterWidth={60} key={item.id}>
                  <Col xs={12} sm={11}>
                    <TextInput
                      id={item.textName}
                      label="Item da lista"
                      type="text"
                      name={item.textName}
                      value={formik.values[item.textName]}
                      onChange={(event) => registration.onChange(event, formik)}
                      onBlur={formik.handleBlur}
                      errorMessage={
                        (formik.touched[item.textName] &&
                          formik.errors[item.textName]) ||
                        ''
                      }
                      placeholder="Ex: Aceito em 100% das rodovias"
                    />
                  </Col>
                  <Col xs={12} sm={1}>
                    <Button
                      id="btn_save_account"
                      variant="icon"
                      onClick={() =>
                        handleEditTextAndLinkBtnInputList(
                          idx === formList.length - 1 && formList.length !== 6
                            ? 'add'
                            : 'remove',
                          item.id
                        )
                      }
                    >
                      <Image
                        src={
                          idx === formList.length - 1 && idx < 5
                            ? PlusIcon
                            : MinusIcon
                        }
                        alt="plus"
                      />
                    </Button>
                  </Col>
                </Row>
              ))}
              <Row direction="row-reverse">
                <Col md={6}>
                  <Row direction="row-reverse">
                    <Button id="btn_save_account" type="submit">
                      Continuar
                    </Button>
                  </Row>
                </Col>
                <Col md={6} >
                  <Button id="btn_voltar" type="button" onClick={() => onPreviousPage()}>
                    Voltar
                  </Button>
                </Col>
              </Row>
            </CardContainer>
          </Col>
          <Col xs={12} sm={3}>
            <CardContainer>
              <FileUpload
                id="uploadImageBenefits"
                name="uploadImageBenefits"
                value={formik.values.uploadImageBenefits}
                onClick={() => {
                  formik.setFieldError(
                    'uploadImageBenefits',
                    'Campo obrigatório'
                  )
                  formik.setFieldTouched('uploadImageBenefits', true, false)
                }}
                onChange={(value) =>
                  registration.setProp('uploadImageBenefits', value, formik)
                }
                onBlur={formik.handleBlur}
                errorMessage={
                  (formik.touched.uploadImageBenefits &&
                    formik.errors.uploadImageBenefits) ||
                  ''
                }
              />
            </CardContainer>
          </Col>
        </Row>
      </Form>
    </Grid>
  )
}

BenefitsPage.propTypes = {
  onNextPage: PropTypes.func.isRequired
}
