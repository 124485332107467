import React from 'react'

import PropTypes from 'prop-types'

import * as S from './styled'

export const Col = ({ children, className, ...rest }) => (
  <S.ColWrapper className={className} {...rest}>
    {children}
  </S.ColWrapper>
)

Col.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

Col.defaultProps = {
  className: null
}
