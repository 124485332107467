import styled from 'styled-components'

export const Footer = styled.footer`
  background-color: ${({ theme }) => theme.colors.offwhite.light};
  color: ${({ theme }) => theme.colors.black};
  font-size: ${({ theme }) => theme.fontSize.base};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  height: 5vh;
  padding-left: ${({ theme }) => theme.spacing.max};
`
export const GridContainer = styled.div`
  display: block;
  & > h1,
  h2 {
    margin-bottom: ${({ theme }) => theme.spacing.max};
  }
  width: 100%;
`
