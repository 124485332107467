import React from 'react'
import { RiErrorWarningFill, RiCloseFill } from 'react-icons/ri'

import PropTypes from 'prop-types'

import * as S from './styles'

export const Alert = ({
  variant,
  children,
  close,
  onClose,
  spacing,
  ...props
}) => (
  <S.Wrapper variant={variant} spacing={spacing} {...props}>
    <RiErrorWarningFill className="icon info" />
    <S.Text>{children}</S.Text>

    {close && <RiCloseFill className="icon close" onClick={onClose} />}
  </S.Wrapper>
)

Alert.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(['success', 'info', 'error']),
  spacing: PropTypes.oneOf([
    '',
    'min',
    'xs',
    'smx',
    'sm',
    'sml',
    'base',
    'mdx',
    'md',
    'mdl',
    'lgx',
    'lg',
    'lgl',
    'max'
  ]),
  onClose: PropTypes.func,
  close: PropTypes.bool
}

Alert.defaultProps = {
  variant: 'default',
  spacing: '',
  onClose: () => {},
  close: true
}
