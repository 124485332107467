import styled, { keyframes } from 'styled-components'

export const Container = styled.div`
  align-items: center;
  display: flex;
`
export const BorderBox = styled.div`
  align-items: center;
  border: solid 1px ${({ theme }) => theme.colors.text.tertiary};
  border-left: none;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  height: 32px;
  margin-left: ${({ theme }) => theme.spacing.md};
  width: 145px;
`

export const ColorBox = styled.div`
  background-color: ${({ hexSelected }) => hexSelected};
  border-radius: 3px;
  height: 32px;
  margin-right: ${({ theme }) => theme.spacing.smx};
  width: 32px;
`

export const RightLeft = keyframes`
 from{
    transform: scale(0);
    opacity:0;
     }
 to{
    transform: scale(1);
    opacity: 1;
     }

`

export const ModalWrapper = styled.div`
  align-items: center;
  animation: ${RightLeft} 0.2s;
  background: rgba(202, 202, 202, 0.3);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 100;
`

export const Action = styled.div`
  align-items: center;
  display: flex;
  margin-top: ${({ theme }) => theme.spacing.sm};

  button:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing.sm};
  }
`
