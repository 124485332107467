import styled from 'styled-components'

export const AddButton = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid #f2f2f2;
  border-radius: 6px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 130px;
  margin-bottom: 32px;
  padding-left: 20px;
  width: 290px;

  image {
    height: 48px;
    width: 48px;
  }
`

export const EditButton = styled.button`
  background: white;
  border: 1px solid #bec4cc;
  border-radius: 90px;
  box-sizing: border-box;
  color: #293b8a;
  height: 48px;
  padding: 12px 16px;
  width: 210px;
`
