import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import * as S from './styles'

export const Loader = ({ time, variant, show }) => {
  const [showDelayMessage, setShowDelayMessage] = useState(false)

  useEffect(() => {
    if (time > 0) {
      const closeOnTime = setTimeout(() => {
        setShowDelayMessage(!showDelayMessage)
      }, time * 1000)

      return () => {
        clearTimeout(closeOnTime)
      }
    }

    return
  }, [])

  return (
    <S.Wrapper show={show}>
      <S.Content data-id="img_loader">
        {variant === 'default' && (
          <>
            <S.Placeholder className="image" />
            <S.Placeholder className="input" />
            <S.Placeholder className="input" />
            <S.Placeholder className="paragraph" />
            <S.Placeholder className="button" />
          </>
        )}
        {variant === 'form' && (
          <>
            <S.Placeholder className="title" />
            <S.Placeholder className="paragraph" />
            <S.Placeholder className="input" />
            <S.Placeholder className="input" />
            <S.Placeholder className="input" />

            <S.Placeholder className="button" />
          </>
        )}
        {variant === 'card' && (
          <>
            <S.Placeholder className="title" />
            <S.Placeholder className="paragraph" />
            <S.Placeholder className="paragraph" />
            <S.Placeholder className="card" />
            <S.Placeholder className="card" />
            <S.Placeholder className="button" />
          </>
        )}
      </S.Content>
    </S.Wrapper>
  )
}

Loader.propTypes = {
  variant: PropTypes.oneOf(['default', 'card', 'form']),
  time: PropTypes.number,
  show: PropTypes.bool.isRequired
}

Loader.defaultProps = {
  variant: 'default',
  time: 0
}
