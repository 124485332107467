import React from 'react'
import PropTypes from 'prop-types'
import * as S from './styled'

const Image = ({ src, alt, className, ...rest }) => (
  <S.ImageWrapper className={className} src={src} alt={alt} {...rest} />
)

Image.propTypes = {
  src: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.node,
    PropTypes.elementType
  ]).isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string
}

Image.defaultProps = {
  className: null
}

export { Image }
