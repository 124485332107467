import {
  GET_PLANS_FAILURE,
  GET_PLANS_REQUEST,
  GET_PLANS_SUCCESS
} from 'redux/constants/Lists/Plan'

const initialState = {
  loading: false,
  error: false,
  payload: []
}

const Plans = (state = initialState, action) => {
  switch (action.type) {
    case GET_PLANS_REQUEST:
      return {
        payload: [],
        loading: true,
        error: false
      }
    case GET_PLANS_SUCCESS:
      return {
        payload: action.payload.dados,
        loading: false,
        error: false
      }
    case GET_PLANS_FAILURE:
      return {
        payload: [],
        loading: false,
        error: true
      }
    default:
      return state
  }
}

export default Plans
