import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'
import {
  spinnerMiddleware,
  snackbarErrorMiddleware,
  snackbarSuccessMiddleware,
  snackbarHandleExceptionMiddleware
} from 'redux/Middleware'
import createRootReducer from 'redux/reducers'

export default createStore(
  createRootReducer(),
  composeWithDevTools(
    applyMiddleware(
      thunkMiddleware,
      spinnerMiddleware,
      snackbarErrorMiddleware,
      snackbarSuccessMiddleware,
      snackbarHandleExceptionMiddleware
    )
  )
)
