import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Typography, Spinner, Snackbar } from 'components/common'
import { logout as logoutService } from 'services/auth'
import { Header } from './Header'
import { Footer } from './Footer'
import SideBar from './SideBar'
import * as S from './styled'

export const PrivateTemplate = ({ children }) => {
  const {
    auth: {
      logout: { payload: logoutResponse }
    }
  } = useSelector((state) => state)

  const {
    wrapper: {
      spinner: { payload: showSpinner }
    },
    dialogs: {
      snackbar: { payload: snackbar }
    }
  } = useSelector((state) => state)

  useEffect(() => {
    if (logoutResponse) logoutService()
  }, [logoutResponse])

  return (
    <>
      <Spinner active={showSpinner}>
        <Snackbar
          show={snackbar.show}
          type={snackbar.type}
          alwaysVisible={snackbar.alwaysVisible}
          secondTimeOutToClose={snackbar.secondTimeOutToClose}
          message={snackbar.message}
        />
        <SideBar />
        <S.Container>
          <Header />
          <S.Main>
            <S.Content>
              <Typography tag="h1" variant="secondary">
                {children}
              </Typography>
            </S.Content>
          </S.Main>
          <Footer />
        </S.Container>
      </Spinner>
    </>
  )
}

PrivateTemplate.propTypes = {
  children: PropTypes.node.isRequired
}
