export const masks = {
  text: {
    mask: '',
    maskPlaceholder: ''
  },
  cel: {
    mask: '(99) 99999-9999',
    maskPlaceholder: '(__) _____-____'
  },
  tel: {
    mask: '(99) 9999-9999',
    maskPlaceholder: '(__) ____-____'
  },
  cep: {
    mask: '99999-999',
    maskPlaceholder: '_____-___'
  },
  cpf: {
    mask: '999.999.999-99',
    maskPlaceholder: '___.___.___-__'
  },
  cnpj: {
    mask: '99.999.999/9999-99',
    maskPlaceholder: '__.___.___/____-__'
  },
  rg: {
    mask: '************',
    maskPlaceholder: ''
  },
  rne: {
    mask: '999999999999999',
    maskPlaceholder: ''
  },
  data: {
    mask: '99/99/9999',
    maskPlaceholder: 'dd/mm/aaaa'
  },
  dateHour: {
    mask: '99/99/9999 99:99',
    maskPlaceholder: 'dd/mm/aaaa hh:mm'
  },
  currency: {
    mask: '999999999999999',
    maskPlaceholder: ''
  },
  cnh: {
    mask: '999999999999999',
    maskPlaceholder: ''
  },
  externalId: {
    mask: '99999999999999999',
    maskPlaceholder: ''
  }
}
