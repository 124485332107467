import React, { useState, useEffect } from 'react'
import { isEmpty } from 'lodash'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import {
  Col,
  Row,
  TextInput,
  TextareaInput,
  Autocomplete,
  CheckBox,
  Typography,
  Button,
  Image,
  Form
} from 'components/common'
import { offerItemPJSchema } from 'utils/schemas'
import { useRegistration } from 'hooks'
import PlusIcon from 'assets/icons/plus.svg'

export const ItemOfferPJ = ({ plansList, onAddOffer, edit, onEditOffer }) => {
  const registration = useRegistration()

  const [enableHighlight, setEnableHighlight] = useState(false)

  const clearForm = () => {
    formik.resetForm()
    setEnableHighlight(false)
  }

  const handleSubmit = () => {
    if (!isEmpty(edit)) onEditOffer({ ...formik.values, id: edit.id })
    else onAddOffer(formik.values)
    clearForm()
  }

  const formik = useFormik({
    initialValues: {
      planOffers: 'PrePagoEmpresarial',
      planPriceOffers: '20,90',
      tagOffers: '',
      supportTextOfferAdd: '',
      textOffers: '',
      enableHighlight: false
    },
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: handleSubmit,
    validationSchema: () => offerItemPJSchema
  })

  useEffect(() => {
    if (!isEmpty(edit)) {
      formik.setFieldValue('planOffers', edit.planOffers)
      formik.setFieldValue('planPriceOffers', edit.planPriceOffers)
      formik.setFieldValue('tagOffers', edit.tagOffers)
      formik.setFieldValue('supportTextOfferAdd', edit.supportTextOfferAdd)
      formik.setFieldValue('textOffers', edit.textOffers)
      formik.setFieldValue('enableHighlight', edit.enableHighlight)
      setEnableHighlight(edit.enableHighlight)
    } else {
      clearForm()
    }
  }, [edit])

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Row align="center" gutterWidth={40}>
        <Col xs={12} sm={6}>
          <Autocomplete
            disabled="true"
            label="Plano*"
            id="planOffers"
            name="planOffers"
            value={formik.values.planOffers}
            onBlur={() => {
              formik.setFieldTouched(
                'planOffers',
                true,
                !formik.errors.planOffers
              )
            }}
            onChange={(value) => {
              registration.setProp('planOffers', value, formik)
            }}
            options={plansList.filter(q => q.tipoCliente === "PARASUAEMPRESA").map((item) => ({
              value: item.nomeDoPlano,
              label: item.descricaoDoPlano
            }))}
            errorMessage={
              (formik.touched.planOffers && formik.errors.planOffers) || ''
            }
          />
        </Col>
        <Col xs={12} sm={6}>
          <TextInput
            disabled="true"
            id="planPriceOffers"
            label="Preço do plano*"
            type="text"
            name="planPriceOffers"
            value={formik.values.planPriceOffers}
            onChange={(event) => registration.onChange(event, formik)}
            onBlur={formik.handleBlur}
            errorMessage={
              (formik.touched.planPriceOffers &&
                formik.errors.planPriceOffers) ||
              ''
            }
            placeholder="Ex: 20,00"
            currency
          />
        </Col>
      </Row>
      <Row align="center" gutterWidth={40}>
        <Col xs={12} sm={4}>
          <TextInput
            id="tagOffers"
            label="TAG*"
            type="text"
            name="tagOffers"
            value={formik.values.tagOffers}
            onChange={(event) => registration.onChange(event, formik)}
            onBlur={formik.handleBlur}
            errorMessage={
              (formik.touched.tagOffers && formik.errors.tagOffers) || ''
            }
            placeholder="Ex: 12 mensalidades grátis"
            maxLength={120}
          />
        </Col>
        {
          <Col xs={12} sm={4}>
            <TextInput
              id="supportTextOfferAdd"
              label="Texto de suporte*"
              type="text"
              name="supportTextOfferAdd"
              value={formik.values.supportTextOfferAdd}
              onChange={(event) => registration.onChange(event, formik)}
              onBlur={formik.handleBlur}
              errorMessage={
                (formik.touched.supportTextOfferAdd &&
                  formik.errors.supportTextOfferAdd) ||
                ''
              }
            />
          </Col>
        }
      </Row>
      <Row>
        <Col xs={12}>
          <TextareaInput
            id="textOffers"
            label="Texto de apoio*"
            name="textOffers"
            value={formik.values.textOffers}
            onChange={(event) => registration.onChange(event, formik)}
            onBlur={formik.handleBlur}
            errorMessage={
              (formik.touched.textOffers && formik.errors.textOffers) || ''
            }
            placeholder="Digite o texto"
            maxLength={800}
          />
        </Col>
      </Row>
      <Row align="center" gutterWidth={40}>
        <Col xs={12}>
          <CheckBox
            onSelected={() => {
              setEnableHighlight(!enableHighlight)
              registration.setProp('enableHighlight', !enableHighlight, formik)
            }}
            selected={enableHighlight}
            checkboxProps={{
              value: 'enableHighlight',
              label: 'Habilitar este card como destaque nas opções'
            }}
          />
        </Col>
      </Row>

      <Row align="center" gutterWidth={40} spacing="lg">
        <Col xs={12} style={{ display: 'flex' }}>
          <Typography tagOffers="span" style={{ marginRight: 12 }}>
            {!isEmpty(edit) ? 'Editar plano' : 'Adicionar novo plano'}
          </Typography>
          <Button
            id="btn_save_account"
            variant="icon"
            type="submit"
            onClick={() => {}}
          >
            <Image src={PlusIcon} alt="plus" />
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

ItemOfferPJ.propTypes = {
  plansList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onAddOffer: PropTypes.func.isRequired,
  onEditOffer: PropTypes.func.isRequired,
  edit: PropTypes.shape({
    planOffers: PropTypes.string,
    textOffers: PropTypes.string,
    paymentSelected: PropTypes.string,
    enableHighlight: PropTypes.bool,
    tagOffers: PropTypes.string,
    supportTextOfferAdd: PropTypes.string,
    planPriceOffers: PropTypes.string,
    id: PropTypes.string
  })
}
ItemOfferPJ.defaultProps = {
  edit: {}
}
