import { TOKEN_ADMIN, USER } from './constants'

export const isAuthenticated = () => !!localStorage.getItem(TOKEN_ADMIN)
export const getToken = () => localStorage.getItem(TOKEN_ADMIN)
export const getUserName = () => localStorage.getItem(USER)
export const getUser = () => {
  const userResponse = localStorage.getItem(USER)
  return (userResponse && JSON.parse(userResponse)) || {}
}
export const login = (token) => {
  localStorage.setItem(TOKEN_ADMIN, token)
}
export const setUserName = (userName) => {
  localStorage.setItem(USER, userName)
}
export const logout = () => {
  // TODO: Revisar para usar o https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/926f1c2ba0598575e23dfd8cdd8b79fa3a3d19ff/lib/msal-browser/docs/logout.md
  localStorage.clear()
  sessionStorage.clear()
  window.location.href = '/entrar'
}
export const setUser = (user) => {
  localStorage.setItem(USER, JSON.stringify(user))
}
