import { strDateTimeToISO_8601 } from '../../../utils/format/string'

const DEFAULT_COLOR = '#FF6338'

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

export const mapRegistrationDataToLandingPagePayload = async (
  registration, status
) => {

  var mainPanel = {
    corPrimaria: registration.mainPainelPrimaryColor || DEFAULT_COLOR,
    corSecundaria: registration.mainPainelSecondaryColor || DEFAULT_COLOR,
    infoTag: registration.infoTag,
    tituloPrincipal: registration.title,
    textoPrincipal: registration.supportText
  };

  var benefitPanel = {
    corPrimaria: registration.benefitsPrimaryColor || DEFAULT_COLOR,
    corSecundaria: registration.benefitsSecondaryColor || DEFAULT_COLOR,
    tituloBeneficio: registration.titleBenefits,
    textoBeneficio: registration.supportTextBenefits,
    listaBeneficios:
      (!!registration.benefitsItemsList &&
        registration.benefitsItemsList.map((item) => ({
          nome: item
        }))) ||
      []
  };

  var plans = (offer) => offer.list.map((plan) => ({
    tipoCliente: plan.typePerson,
    tituloPlano: plan.planOffers || 'Plano normal',
    valorPlano: plan.planPriceOffers,
    dataInicio: plan.startOffers ? strDateTimeToISO_8601(plan.startOffers) : undefined,
    dataFim: plan.endOffers ? strDateTimeToISO_8601(plan.endOffers) : undefined,
    taxaMensalidadeId: plan.monthlyTaxOffers || 1,
    prioridade: plan.priorityOffers ? parseInt(plan.priorityOffers) : 1,
    percentualIsencao: plan.exemptionPercentageOffers ? parseInt(plan.exemptionPercentageOffers) : 0,
    quantidadeMesesIsencao: plan.monthsExemptionOffers ? parseInt(plan.monthsExemptionOffers) : 0,
    idProdutoSys: 1,
    textoSuporte: plan.supportTextOfferAdd,
    textoTag: plan.tagOffers,
    textoApoio: plan.textOffers,
    criterios: [
      {
        tipo: plan.kindOfCriterionOffers,
        valor: [`${plan.criterionOffers}`]
      }
    ],
    planoDestaque: plan.enableHighlight,
    isentarAdesoes: true
  }));

  var offer = registration.listWithOffersTypes.map((offer) => ({
      corPrimaria: registration.offersPrimaryColor,
      corSecundaria: registration.offersSecondaryColor,
      tituloOfertas: registration.titleOffers,
      textoOfertas: registration.supportTextOffers,
      planos: plans(offer)
    }));

  var partnerAbout = {
    corPrimaria: registration.partnershipPrimaryColor || DEFAULT_COLOR,
    corSecundaria: registration.partnershipSecondaryColor || DEFAULT_COLOR,
    tituloSobreParceria: registration.titlePartnership,
    textoSobreParceria: registration.supportTextPartnership
  }

  var faq = {
    corPrimaria: registration.questPrimaryColor || DEFAULT_COLOR,
    corSecundaria: registration.questPrimaryColor || DEFAULT_COLOR,
    itens: registration.faqItens || []
  }

  var centralService = {
    corPrimaria: registration.centralPrimaryColor || DEFAULT_COLOR,
    corSecundaria: registration.centralSecondaryColor || DEFAULT_COLOR,
    textoAtendimento: registration.supportTextCentral,
    tagAtendimento: registration.titleCentral,
    urlAtendimento: registration.linkBtnCentral
  }

  var file = {
    linkImagemPrincipal: (registration.uploadImage.name && (await toBase64(registration.uploadImage))) || registration.uploadImage,
    linkImagemBeneficio: (registration.uploadImageBenefits.name && (await toBase64(registration.uploadImageBenefits))) || registration.uploadImageBenefits,
    linkImagemSobreParceria: (registration.uploadImagePartnership.name && (await toBase64(registration.uploadImagePartnership))) || registration.uploadImagePartnership,
    logoParceiro: (registration.uploadImageTheme.name && (await toBase64(registration.uploadImageTheme))) || registration.uploadImageTheme,
    termoAdesao: (registration.uploadTerm.name && (await toBase64(registration.uploadTerm))) || registration.uploadTerm,
    politicaPrivacidade: (registration.uploadPdf.name && (await toBase64(registration.uploadPdf))) || registration.uploadPdf
  }

  var payload = {
    nomeParceiro: registration.titleTheme,
    titulo: registration.titleTheme,
    descricao: registration.descriptionTheme,
    textoJuridico: registration.legalTextTheme,
    dns: registration.dnsTheme,
    painelPrincipal: mainPanel,
    painelBeneficio: benefitPanel,
    ofertas: offer,
    sobreParceria: partnerAbout,
    faq,
    centralAtendimento: centralService,
    arquivo: file,
    status
  }

  return payload;
}

export const mapLandingPagePayloadToRegistrationData = (ladingPage) => ({
  tag: ladingPage.painelPrincipal.infoTag,
  mainPainelPrimaryColor:
    ladingPage.painelPrincipal.corPrimaria || DEFAULT_COLOR,
  mainPainelSecondaryColor:
    ladingPage.painelPrincipal.corSecundaria || DEFAULT_COLOR,
  title: ladingPage.painelPrincipal.tituloPrincipal,
  supportText: ladingPage.painelPrincipal.textoPrincipal,
  uploadImage: ladingPage.arquivo.linkImagemPrincipal,

  titleBenefits: ladingPage.painelBeneficio.tituloBeneficio,
  benefitsPrimaryColor: ladingPage.painelBeneficio.corPrimaria,
  benefitsSecondaryColor: ladingPage.painelBeneficio.corSecundaria,
  supportTextBenefits: ladingPage.painelBeneficio.textoBeneficio,
  uploadImageBenefits: ladingPage.arquivo.linkImagemBeneficio,
  formListBenefits: ladingPage.painelBeneficio.listaBeneficios,

  offersPrimaryColor: ladingPage.ofertas[0].corPrimaria,
  offersSecondaryColor: ladingPage.ofertas[0].corSecundaria,
  titleOffers: ladingPage.ofertas[0].tituloOfertas,
  supportTextOffers: ladingPage.ofertas[0].textoOfertas,
  listPlans: ladingPage.ofertas[0].planos,

  partnershipPrimaryColor: ladingPage.sobreParceria.corPrimaria,
  partnershipSecondaryColor: ladingPage.sobreParceria.corSecundaria,
  titlePartnership: ladingPage.sobreParceria.tituloSobreParceria,
  supportTextPartnership: ladingPage.sobreParceria.textoSobreParceria,
  uploadImagePartnership: ladingPage.arquivo.linkImagemSobreParceria,

  questPrimaryColor: ladingPage.faq.corPrimaria,
  faqItens: ladingPage.faq.itens,

  centralPrimaryColor: ladingPage.centralAtendimento.corPrimaria,
  centralSecondaryColor: ladingPage.centralAtendimento.corSecundaria,
  supportTextCentral: ladingPage.centralAtendimento.textoAtendimento,
  titleCentral: ladingPage.centralAtendimento.tagAtendimento,
  linkBtnCentral: ladingPage.centralAtendimento.urlAtendimento,

  titleTheme: ladingPage.nomeParceiro,
  descriptionTheme: ladingPage.descricao,
  legalTextTheme: ladingPage.textoJuridico,
  dnsTheme: ladingPage.dns,
  uploadImageTheme: ladingPage.arquivo.logoParceiro,
  uploadTerm: ladingPage.arquivo.termoAdesao,
  uploadPdf: ladingPage.arquivo.politicaPrivacidade,
  enableHighlight: ladingPage.planoDestaque
})
