import React from 'react'
import PropTypes from 'prop-types'

import * as S from './styled'

export const CheckBoxGroup = ({ spacing, checkboxList, onSelected, value }) => {
  return (
    <S.WrapperCheckboxGroup spacing={spacing}>
      {checkboxList.map((item) => (
        <S.Checkbox
          onClick={() => onSelected(item.value)}
          variant={value === item.value ? 'selected' : 'primary'}
          key={item.value}
        >
          <S.Radio selected={value === item.value}>
            <svg
              width="12"
              height="10"
              viewBox="0 0 12 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.08 7.32721L10.4977 0.763604C10.8414 0.412132 11.3986 0.412132 11.7423 0.763604C12.0859 1.11508 12.0859 1.68492 11.7423 2.0364L4.70225 9.2364C4.35859 9.58787 3.80141 9.58787 3.45775 9.2364L0.257746 5.96367C-0.0859153 5.6122 -0.0859153 5.04235 0.257746 4.69088C0.601407 4.3394 1.15859 4.3394 1.50225 4.69088L4.08 7.32721Z"
                fill="white"
              />
            </svg>
          </S.Radio>
          <span>{item.label}</span>
        </S.Checkbox>
      ))}
    </S.WrapperCheckboxGroup>
  )
}

CheckBoxGroup.propTypes = {
  value: PropTypes.string.isRequired,
  onSelected: PropTypes.func.isRequired,
  checkboxList: PropTypes.oneOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      selected: PropTypes.bool
    })
  ),
  spacing: PropTypes.oneOf([
    '',
    'min',
    'xs',
    'smx',
    'sm',
    'sml',
    'base',
    'mdx',
    'md',
    'mdl',
    'lgx',
    'lg',
    'lgl',
    'max'
  ])
}

CheckBoxGroup.defaultProps = {
  spacing: '',
  checkboxList: []
}
