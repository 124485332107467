import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  RiDeleteBin6Line,
  RiEditLine,
  RiArrowRightSLine,
  RiArrowDownSLine
} from 'react-icons/ri'
import * as S from './styles'

export const Accordion = ({
  children,
  title,
  active,
  onDelete,
  onEdit,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(active)

  const handleToggle = () => setIsOpen(!isOpen)

  return (
    <S.Wrapper {...props}>
      <S.Title active={isOpen}>
        <S.WrapperTitle onClick={handleToggle}>{title}</S.WrapperTitle>

        <S.Action>
          <RiEditLine onClick={onEdit} />
          <RiDeleteBin6Line onClick={onDelete} />
          {!isOpen && (
            <RiArrowRightSLine
              onClick={handleToggle}
              style={{ fontSize: 30 }}
            />
          )}
          {isOpen && (
            <RiArrowDownSLine onClick={handleToggle} style={{ fontSize: 30 }} />
          )}
        </S.Action>
      </S.Title>

      <S.Content active={isOpen}>{children}</S.Content>
    </S.Wrapper>
  )
}

Accordion.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  active: PropTypes.bool,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func
}

Accordion.defaultProps = {
  title: '',
  active: false,
  onDelete: () => {},
  onEdit: () => {}
}
