import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { useFormik } from 'formik'
import { useSelector, useDispatch } from 'react-redux'
import {
  Typography,
  Button,
  TextInput,
  ColorPicker,
  TextareaInput,
  CardContainer,
  Grid,
  Col,
  Row,
  Form
} from 'components/common'
import { centralSchema } from 'utils/schemas'
import { useRegistration } from 'hooks'
import { actionSetRegistration } from 'redux/actions/Registration/Data'

export const CentralPage = ({ onPreviousPage, onNextPage }) => {
  const dispatch = useDispatch()
  const registration = useRegistration()
  const {
    partnerData: { payload },
    data: { payload: dataFormGlobalStore }
  } = useSelector(({ registration }) => registration)
  const [fillForm, setFillForm] = useState(false)

  useEffect(() => {
    if (!isEmpty(payload) && !fillForm) {
      formik.setFieldValue(
        'centralPrimaryColor',
        dataFormGlobalStore.centralPrimaryColor || payload.centralPrimaryColor
      )
      formik.setFieldValue(
        'centralSecondaryColor',
        dataFormGlobalStore.centralSecondaryColor ||
        payload.centralSecondaryColor
      )
      formik.setFieldValue(
        'supportTextCentral',
        dataFormGlobalStore.supportTextCentral || payload.supportTextCentral
      )
      formik.setFieldValue(
        'titleCentral',
        dataFormGlobalStore.titleCentral || payload.titleCentral
      )
      formik.setFieldValue(
        'linkBtnCentral',
        dataFormGlobalStore.linkBtnCentral || payload.linkBtnCentral
      )

      dispatch(
        actionSetRegistration({
          ...dataFormGlobalStore,
          centralPrimaryColor:
            dataFormGlobalStore.centralPrimaryColor ||
            payload.centralPrimaryColor,
          centralSecondaryColor:
            dataFormGlobalStore.centralSecondaryColor ||
            payload.centralSecondaryColor,
          supportTextCentral:
            dataFormGlobalStore.supportTextCentral ||
            payload.supportTextCentral,
          titleCentral:
            dataFormGlobalStore.titleCentral || payload.titleCentral,
          linkBtnCentral:
            dataFormGlobalStore.linkBtnCentral || payload.linkBtnCentral
        })
      )

      setFillForm(true)
    }
  }, [payload, dataFormGlobalStore])

  const handleSubmit = () => onNextPage()

  const formik = useFormik({
    initialValues: {
      titleCentral: dataFormGlobalStore.titleCentral || '',
      supportTextCentral: dataFormGlobalStore.supportTextCentral || '',
      linkBtnCentral: dataFormGlobalStore.linkBtnCentral || '',
      centralPrimaryColor: dataFormGlobalStore.centralPrimaryColor || '',
      centralSecondaryColor: dataFormGlobalStore.centralSecondaryColor || ''
    },
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: handleSubmit,
    validationSchema: () => centralSchema
  })

  return (
    <Grid>
      <Form onSubmit={formik.handleSubmit}>
        <Row>
          <Col xs={12} sm={8}>
            <CardContainer>
              <Typography tag="h2" variant="secondary" spacing="mdx">
                Preencha as informações do bloco
              </Typography>
              <Row spacing="lg">
                <Col xs={12} sm={6}>
                  <ColorPicker
                    key="principal"
                    labelText="Cor principal do bloco"
                    hex={formik.values.centralPrimaryColor}
                    onChangeColor={(value) =>
                      registration.setProp('centralPrimaryColor', value, formik)
                    }
                  />
                </Col>
                <Col xs={12} sm={6}>
                  <ColorPicker
                    key="principal"
                    labelText="Cor secundária do bloco"
                    hex={formik.values.centralSecondaryColor}
                    onChangeColor={(value) =>
                      registration.setProp(
                        'centralSecondaryColor',
                        value,
                        formik
                      )
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <TextInput
                    id="titleCentral"
                    label="Tag"
                    type="text"
                    name="titleCentral"
                    value={formik.values.titleCentral}
                    onChange={(event) => registration.onChange(event, formik)}
                    onBlur={formik.handleBlur}
                    errorMessage={
                      (formik.touched.titleCentral &&
                        formik.errors.titleCentral) ||
                      ''
                    }
                    placeholder="EX: Central"
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <TextareaInput
                    id="supportTextCentral"
                    label="Texto de apoio"
                    name="supportTextCentral"
                    value={formik.values.supportTextCentral}
                    onChange={(event) => registration.onChange(event, formik)}
                    onBlur={formik.handleBlur}
                    errorMessage={
                      (formik.touched.supportTextCentral &&
                        formik.errors.supportTextCentral) ||
                      ''
                    }
                    placeholder="Ex: Para instalar seu adesivo..."
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <TextInput
                    id="linkBtnCentral"
                    label="Link do botão"
                    type="text"
                    name="linkBtnCentral"
                    value={formik.values.linkBtnCentral}
                    onChange={(event) => registration.onChange(event, formik)}
                    onBlur={formik.handleBlur}
                    errorMessage={
                      (formik.touched.linkBtnCentral &&
                        formik.errors.linkBtnCentral) ||
                      ''
                    }
                    placeholder="Ex: https://conectcar.com."
                  />
                </Col>
              </Row>

              <Row direction="row-reverse">
                <Col md={6}>
                  <Row direction="row-reverse">
                    <Button id="btn_save_account" type="submit">
                      Continuar
                    </Button>
                  </Row>
                </Col>
                <Col md={6}>
                  <Button id="btn_voltar" type="button" onClick={() => onPreviousPage()}>
                    Voltar
                  </Button>
                </Col>
              </Row>
            </CardContainer>
          </Col>
        </Row>
      </Form>
    </Grid>
  )
}

CentralPage.propTypes = {
  onNextPage: PropTypes.func.isRequired
}
