import styled from 'styled-components'

export const Tab = styled.div`
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;

  align-items: center;
  border-bottom: 1px solid
    ${({ theme, selected }) =>
      selected ? theme.colors.primary.base : theme.colors.text.tertiary};  
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing.sml};
  min-width: 157px;
  padding-bottom: ${({ theme }) => theme.spacing.sm};
  transition: ${({ theme }) => theme.transition.base};
  user-select: none;

  span {
    color: ${({ theme, selected }) =>
      selected ? theme.colors.text.secondary : theme.colors.text.tertiary};
    font-size: ${({ theme }) => theme.fontSize.lg};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    transition: ${({ theme }) => theme.transition.base};
  }

  img {
    margin-left: ${({ theme }) => theme.spacing.sml};
  }
`

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${({ theme }) => theme.spacing.mdl};
  width: 100%;

  ${Tab}:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing.sml};
  }
`

export const Content = styled.div`
  margin-top: ${({ theme }) => theme.spacing.sml};
  width: 100%;
`
