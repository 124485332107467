import { GET_LANDING_PAGE_BY_ID_SUCCESS } from 'redux/constants/LandingPage/Data'
import { SET_REGISTRATION_PARTNER_DATA } from 'redux/constants/Registration/Data'

import { mapLandingPagePayloadToRegistrationData } from 'redux/mapping/Registration'

const initialState = {
  payload: {}
}

const PartnerData = (state = initialState, action) => {
  switch (action.type) {
    case GET_LANDING_PAGE_BY_ID_SUCCESS:
      return {
        payload: mapLandingPagePayloadToRegistrationData(action.payload.dados),
        loading: false,
        error: false
      }
    case SET_REGISTRATION_PARTNER_DATA:
      return {
        payload: action.payload,
        loading: false,
        error: false
      }
    default:
      return state
  }
}

export default PartnerData
