import {
  GET_ALL_LANDING_PAGE_DATA_FAILURE,
  GET_ALL_LANDING_PAGE_DATA_REQUEST,
  GET_ALL_LANDING_PAGE_DATA_SUCCESS,
  CREATE_LANDING_PAGE_DATA_FAILURE,
  CREATE_LANDING_PAGE_DATA_REQUEST,
  CREATE_LANDING_PAGE_DATA_SUCCESS,
  GET_LANDING_PAGE_BY_ID_REQUEST,
  GET_LANDING_PAGE_BY_ID_FAILURE,
  GET_LANDING_PAGE_BY_ID_SUCCESS,
  UPDATE_LANDING_PAGE_DATA_FAILURE,
  UPDATE_LANDING_PAGE_DATA_REQUEST,
  UPDATE_LANDING_PAGE_DATA_SUCCESS
} from 'redux/constants/LandingPage/Data'
import { getAll, create, getById, update, disable } from 'config/api/LandingPage'
import { mapRegistrationDataToLandingPagePayload } from 'redux/mapping/Registration'

// GET ALL
const landingPageRequest = () => ({
  type: GET_ALL_LANDING_PAGE_DATA_REQUEST
})

const landingPageSuccess = (response) => ({
  type: GET_ALL_LANDING_PAGE_DATA_SUCCESS,
  payload: response
})

const landingPageFailure = (payload) => ({
  type: GET_ALL_LANDING_PAGE_DATA_FAILURE,
  payload
})

const disableLandingPage = (id, dispatch) => {
  dispatch(landingPageRequest())
  disable(id)
    .then(() => {
      getAllLandingPage({ maximoItemPorPagina: 10 }, dispatch)
    })
    .catch((error) => {
      dispatch(landingPageFailure(error.response))
    })
}

const getAllLandingPage = (filters, dispatch) => {
  dispatch(landingPageRequest())
  getAll(filters)
    .then((response) => {
      dispatch(landingPageSuccess(response.data))
    })
    .catch((error) => {
      dispatch(landingPageFailure(error.response))
    })
}

export const actionGetAllLandingPage = (filters) => (dispatch) =>
  getAllLandingPage(filters, dispatch)

  export const actionDisableLandingPage = (id) => (dispatch) =>
  disableLandingPage(id, dispatch)

// POST
const createLandingPageRequest = () => ({
  type: CREATE_LANDING_PAGE_DATA_REQUEST
})

const createLandingPageSuccess = (response) => ({
  type: CREATE_LANDING_PAGE_DATA_SUCCESS,
  payload: response
})
const createLandingPageFailure = (payload) => ({
  type: CREATE_LANDING_PAGE_DATA_FAILURE,
  payload
})

const createLandingPage = async (request, status, dispatch) => {
  dispatch(createLandingPageRequest())
  const requestMapping = await mapRegistrationDataToLandingPagePayload(request, status)
  create(requestMapping)
    .then((response) => {
      dispatch(createLandingPageSuccess(response.data))
    })
    .catch((error) => {
      dispatch(createLandingPageFailure(error.response))
    })
}

export const actionCreateLandingPage = (request, status) => (dispatch) =>
  createLandingPage(request, status, dispatch)

// GET BY ID
const landingPageByIdRequest = () => ({
  type: GET_LANDING_PAGE_BY_ID_REQUEST
})

const landingPageByIdSuccess = (response) => ({
  type: GET_LANDING_PAGE_BY_ID_SUCCESS,
  payload: response
})
const landingPageByIdFailure = (payload) => ({
  type: GET_LANDING_PAGE_BY_ID_FAILURE,
  payload
})

const getLandingPageById = (id, dispatch) => {
  dispatch(landingPageByIdRequest())
  getById(id)
    .then((response) => {
      dispatch(landingPageByIdSuccess(response.data))
    })
    .catch((error) => {
      dispatch(landingPageByIdFailure(error.response))
    })
}

export const actionGetLandingPageById = (id) => (dispatch) =>
  getLandingPageById(id, dispatch)

export const actionSetLandingPagePersistData = (payload) => (dispatch) =>
  dispatch(createLandingPageSuccess(payload))

// PUT
const updateLandingPageRequest = () => ({
  type: UPDATE_LANDING_PAGE_DATA_REQUEST
})

const updateLandingPageSuccess = (response) => ({
  type: UPDATE_LANDING_PAGE_DATA_SUCCESS,
  payload: response
})
const updateLandingPageFailure = (payload) => ({
  type: UPDATE_LANDING_PAGE_DATA_FAILURE,
  payload
})

const updateLandingPage = async (idLP, request, status, dispatch) => {
  dispatch(updateLandingPageRequest())

  const requestMapping = await mapRegistrationDataToLandingPagePayload(request, status)
  
  update(idLP, requestMapping)
    .then((response) => {
      dispatch(updateLandingPageSuccess(response.data))
    })
    .catch((error) => {
      dispatch(updateLandingPageFailure(error.response))
    })
}

export const actionUpdateLandingPage = (idLP, request, status) => (dispatch) =>
  updateLandingPage(idLP, request, status, dispatch)

  export const actionClearLandingPagePersistUpdateData = (payload) => (dispatch) =>
  dispatch(updateLandingPageRequest(payload))
  
