import styled from 'styled-components'

export const Checkbox = styled.div`
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  align-items: center;
  color: ${({ theme }) => theme.colors.gray};
  cursor: pointer;
  display: flex;
  font-size: ${({ theme }) => theme.fontSize.base};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  user-select: none;

  span {
    margin-left: ${({ theme }) => theme.spacing.smx};
  }
`

export const WrapperCheckBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${({ theme, spacing }) =>
    spacing === '' ? 0 : `${theme.spacing[spacing]}`};

  ${Checkbox}:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing.lgl};
  }
`
