import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Image } from 'components/common'
import EditIcon from 'assets/icons/edit.svg'
import EditDisabledIcon from 'assets/icons/edit-disabled.svg'
import * as S from './styled'

const pageLink = {
  mainpanel: 'Painel principal',
  benefits: 'Benefícios',
  offers: 'Ofertas',
  partnership: 'Sobre a parceria',
  questions: 'Perguntas',
  central: 'Central',
  theme: 'Configuração do tema'
}

export const Tabs = ({ children, onChange, tab }) => {
  const [selected, setSelected] = useState('mainpanel')

  useEffect(() => setSelected(tab), [tab])

  return (
    <S.Container>
      {Object.entries(pageLink).map(([key, value]) => {
        return (
          <S.Tab
            key={key}
            selected={selected === key}
            onClick={() => {
              //setSelected(key)
              if (onChange != null)
                onChange(key)
            }}
          >
            <span>{value}</span>
            <Image src={selected === key ? (EditIcon && EditIcon) : (EditDisabledIcon && EditDisabledIcon)} alt="edit" />
          </S.Tab>
        )
      })}
      <S.Content>{children}</S.Content>
    </S.Container>
  )
}

Tabs.propTypes = {
  tab: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired
}
