import styled, { css } from 'styled-components'

const variants = {
  success: () => css`
    background-color: ${({ theme }) => theme.colors.success.light};
  `,

  warning: () => css`
    background-color: ${({ theme }) => theme.colors.warning};
  `,

  desactive: () => css`
    background-color: #e74c3c;
  `
}

export const BadgeWrapper = styled.div`
  align-items: center;
  border-radius: 100px;
  display: flex;
  height: 23px;
  justify-content: center;
  min-width: 90px;
  width: 90px;
  ${(props) => variants[props.variant]}
`
