import {
  GET_CRITERIONS_FAILURE,
  GET_CRITERIONS_REQUEST,
  GET_CRITERIONS_SUCCESS
} from 'redux/constants/Lists/Criterions'
import { getCriterions } from 'config/api/Lists'

// GET ALL
const criterionsRequest = () => ({
  type: GET_CRITERIONS_REQUEST
})

const criterionsSuccess = (response) => ({
  type: GET_CRITERIONS_SUCCESS,
  payload: response
})
const criterionsFailure = (payload) => ({
  type: GET_CRITERIONS_FAILURE,
  payload
})

const getAllCriterions = (dispatch) => {
  dispatch(criterionsRequest())
  getCriterions()
    .then((response) => {
      dispatch(criterionsSuccess(response.data))
    })
    .catch((error) => {
      dispatch(criterionsFailure(error.response))
    })
}

export const actionGetCriterions = () => (dispatch) =>
  getAllCriterions(dispatch)
