import React from 'react'
import { Typography } from 'components/common'

export const OverviewPage = () => {
  return (
    <>
      <Typography tag="h1" variant="secondary">
        Visão Geral
      </Typography>
    </>
  )
}
