import { combineReducers } from 'redux'

import data from './Data'
import partnerData from './PartnerData'
import persist from './Persist'
import persistUpdate from './PersistUpdate'

export default combineReducers({
  data,
  partnerData,
  persist,
  persistUpdate
})
