import {
  POST_SIGNIN_FAILURE,
  POST_SIGNIN_REQUEST,
  POST_SIGNIN_SUCCESS,
  POST_SIGNIN_FORBIDDEN
} from 'redux/constants/Auth/SignIn'

const initialState = {
  payload: {}
}

const SignIn = (state = initialState, action) => {
  switch (action.type) {
    case POST_SIGNIN_REQUEST:
      return {
        payload: {}
      }
    case POST_SIGNIN_SUCCESS:
      return {
        payload: action.payload.dados
      }
    case POST_SIGNIN_FAILURE:
    case POST_SIGNIN_FORBIDDEN:
      return {
        payload: {}
      }
    default:
      return state
  }
}

export default SignIn
