const themeDefault = {
  colors: {
    primary: {
      light: '#ffffff',
      base: '#FF6338',
      dark: '#ffffff'
    },
    secondary: {
      light: '#5C5C5C',
      base: '#004077',
      dark: '#161616'
    },
    tertiary: {
      light: '#ededed',
      base: '#E4E4E4',
      dark: '#6C757D'
    },
    success: {
      light: '#298F7A',
      base: '#0B8384',
      dark: '#0A5860'
    },
    critical: {
      light: '#e4a398;',
      base: '#F06466',
      dark: '#e44126'
    },
    text: {
      primary: '#424242',
      secondary: '#293b8a',
      tertiary: '#BEC4CC'
    },
    offwhite: {
      light: '#FBFAFA',
      base: '#e5e5e5'
    },
    gray: '#4D5761',
    white: '#ffffff',
    black: '#2a3037',
    warning: '#FFB200'
  },
  fontSize: {
    xs: '1rem',
    smx: '1.1rem',
    sm: '1.2rem',
    xmd: '1.3rem',
    base: '1.4rem',
    default: '1.5rem',
    md: '1.6rem',
    lg: '1.8rem',
    xl: '2.2rem',
    xxl: '3.2rem'
  },
  fontWeight: {
    base: 400,
    disclaimer: 500,
    bold: 700
  },
  fontFamily: {
    base: 'Lato',
    heading:
      "Ubuntu, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif"
  },
  lineHeight: {
    base: 1.4,
    heading: 1.2
  },
  container: {
    sm: '44rem',
    md: '76rem',
    lg: '116rem',
    xl: '142rem'
  },
  breakpoint: {
    sm: '450px',
    md: '768px',
    lg: '1170px',
    xl: '1900px'
  },
  layers: {
    base: 10,
    menu: 20,
    overlay: 30,
    modal: 40,
    alwaysOnTop: 50
  },
  radius: {
    sm: 0,
    md: '0.4rem',
    lg: '10rem'
  },
  spacing: {
    min: '0.2rem',
    xs: '0.4rem',
    smx: '0.8rem',
    sm: '1.2rem',
    sml: '1.6rem',
    base: '2.0rem',
    mdx: '2.4rem',
    md: '2.8rem',
    mdl: '3.2rem',
    lgx: '3.6rem',
    lg: '4.0rem',
    lgl: '4.4rem',
    max: '6.4rem'
  },
  transition: {
    base: 'all 250ms cubic-bezier(0.77, 0, 0.175, 1)',
    fast: '0.1s ease-in-out'
  },
  shadow: {
    card: '0px 0px 4px 1px rgba(142, 142, 142, 0.3)',
    menu: '6px 0px 18px rgba(0, 0, 0, 0.06)'
  },
  zIndex: {
    min: 1,
    base: 10,
    max: 100
  }
}

export { themeDefault }
