import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import AvatarEditor from 'react-avatar-editor'
import { Image, Typography, Button, HiddenFileInput } from 'components/common'
import UploadIcon from 'assets/icons/upload.svg'

import * as S from './styled'

export const FileUpload = ({
  variant,
  title,
  spacing,
  errorMessage,
  onChange,
  value,
  onClick
}) => {
  const inputRef = useRef(null)
  const editorRef = useRef(null)

  const [fileSelected, setFileSelected] = useState('')
  const [fileSelectedLink, setFileSelectedLink] = useState('')
  const [originalImage, setOriginalImage] = useState('')
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (value) {
      if (value.name !== fileSelected.name) {
        setFileSelected(value)
        setFileSelectedLink('')
      }
      if (!value.name) setFileSelectedLink(value)
    }
  }, [value])

  const handleChangeFile = async (e) => {
    let file = ''
    if (e.target.files) {
      if (e.target.files.length > 0) {
        if (variant === 'image') setOriginalImage(e.target.files[0])
        else setFileSelected(e.target.files[0])
        file = e.target.files[0]
      } else {
        onChange(file)
      }
    }
    if (variant === 'image') setShow(true)
    else onChange(file)
  }

  const content = {
    subtitle: {
      image: 'Anexar imagem',
      document: 'Anexar PDF'
    },
    acceptText: {
      image: 'Arquivos suportados: PNG e JPG',
      document: 'Arquivos suportados: PDF e DOC'
    },
    accept: {
      image: 'image/png, image/jpeg',
      document:
        'application/msword, application/pdf,doc, .docx,.ppt, .pptx,.txt,.pdf'
    },
    maxLength: {
      image: 'Tamanho máximo: 5MB',
      document: 'Tamanho máximo: 5MB'
    }
  }

  const handleDownload = () => {
    const blob = new Blob([fileSelected], { type: fileSelected.type })
    const objectURL = window.URL.createObjectURL(blob)
    window.open(objectURL)
  }

  const handleCancelImage = () => {
    setFileSelected('')
    setShow(false)
  }

  const dataURLtoFile = (dataUrl, filename) => {
    let arr = dataUrl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n)

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }

    return new File([u8arr], filename, { type: mime })
  }

  const handleSelectImage = () => {
    if (editorRef.current) {
      const canvasScaled = editorRef.current
        .getImageScaledToCanvas()
        .toDataURL()
      const file = dataURLtoFile(canvasScaled, originalImage.name)
      setFileSelected(file)
      onChange(file)
      setOriginalImage('')
      setShow(false)
    }
  }

  const handleDownloadLink = (url) => {
    const link = document.createElement('a')
    link.download = url
    link.href = url
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <S.Container spacing={spacing}>
      <Typography tag="default" spacing="sml" align="center">
        {title}
      </Typography>
      <Image src={UploadIcon} alt="upload" />
      <Typography tag="support" spacing="xs">
        {content.subtitle[variant]}
      </Typography>
      <Typography tag="subtitle" spacing="sml">
        {content.acceptText[variant]}
      </Typography>
      <Button
        id="btn_upload"
        onClick={() => {
          setFileSelected('')
          inputRef.current.click()
          onClick()
        }}
        spacing="xs"
      >
        Escolher arquivo
      </Button>
      <HiddenFileInput
        inputType="hiddenFile"
        ref={inputRef}
        onChange={handleChangeFile}
        accept={content.accept[variant]}
      />
      <Typography
        tag="subtitle"
        variant="secondary"
        spacing={fileSelected ? 'sml' : ''}
      >
        {content.maxLength[variant]}
      </Typography>
      {fileSelected && (
        <Button id="download" variant="link-secondary" onClick={handleDownload}>
          {fileSelected.name}
        </Button>
      )}
      {fileSelectedLink && (
        <Button
          id="download"
          variant="link-secondary"
          onClick={() => handleDownloadLink(fileSelectedLink)}
        >
          Arquivo...
        </Button>
      )}
      {originalImage && show && (
        <S.ModalWrapper>
          <AvatarEditor
            image={originalImage}
            width={620}
            height={601}
            ref={editorRef}
          />
          <S.Action>
            <Button
              id="download"
              onClick={handleCancelImage}
              variant="secondary"
            >
              Cancelar
            </Button>
            <Button id="download" onClick={handleSelectImage}>
              Selecionar
            </Button>
          </S.Action>
        </S.ModalWrapper>
      )}
      {errorMessage && <S.ErrorMessage>{errorMessage}</S.ErrorMessage>}
    </S.Container>
  )
}

FileUpload.propTypes = {
  errorMessage: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(['image', 'document']),
  title: PropTypes.string,
  spacing: PropTypes.oneOf([
    '',
    'min',
    'xs',
    'smx',
    'sm',
    'sml',
    'base',
    'mdx',
    'md',
    'mdl',
    'lgx',
    'lg',
    'lgl',
    'max'
  ])
}

FileUpload.defaultProps = {
  variant: 'image',
  title: '',
  spacing: '',
  errorMessage: '',
  value: '',
  onChange: () => {},
  onClick: () => {}
}
