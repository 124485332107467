import styled from 'styled-components'
import breakpoints from 'utils/mediaQueries/media'

export const Container = styled.div`
  display: flex;
  height: 100vh;
  position: relative;
  width: 100%;
`

export const FormLoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 320px;
  padding-right: 130px;
  padding-top: 210px;

  width: 45%;

  @media (max-width: ${breakpoints.md}) {
    padding: 0 ${({ theme }) => theme.spacing.sml};
    margin-top: ${({ theme }) => theme.spacing.max};
    align-items: center;
    width: 100%;
  }

  @media (max-width: ${breakpoints.xl}) {
    padding: 0 ${({ theme }) => theme.spacing.sml};
    margin-top: ${({ theme }) => theme.spacing.max};
  }
`

export const Banner = styled.div`
  display: flex;
  width: 55%;
  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  @media (max-width: ${breakpoints.md}) {
    display: none;
  }
`
export const Footer = styled.div`
  bottom: 37px;
  position: absolute;
`
