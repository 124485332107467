import moment from 'moment'

const numberToReal = (value) => {
  if (value) {
    const formattedValue = value.toFixed(2).split('.')
    formattedValue[0] = formattedValue[0].split(/(?=(?:...)*$)/).join('.')
    return formattedValue.join(',')
  }
  return 0
}

export const getNumCharacterFromString = (str) => {
  const numb = str.match(/\d/g)
  return parseInt(numb.join(''), 10)
}

export const splitAndGetFirst = (str, separador) =>
  str.slice(0, str.indexOf(separador))

export const splitAndSliceByIndex = (str, separador, sliceIndex) => {
  const strSplitted = str.split(separador)
  return strSplitted.slice(sliceIndex)
}

// "2021-11-01T17:40:44.700Z"

export const clearMask = (str) => str.replace(/\s+|[^\w\s]/gi, '')

export const strDataFromBrFormatForAny = (strData, format) =>
  moment(strData, 'DD/MM/YYYY').format(format)

export const strDateTimeToISO_8601 = (strData) => {
  const strDataSplit = strData.split('/')
  return `${strDataSplit[2]}-${strDataSplit[1]}-${strDataSplit[0]}T17:40:44.700Z`
}

export const strDateFromAnyToBr = (strData, format) =>
  moment(strData, 'YYYY-MM-DD').format(format)

export const strDateTime = (strData) =>
  moment(strData).format('DD/MM/YYYY HH:mm')

export const strDate = (strData) => moment(strData).format('DD/MM/YYYY')

export const strDateTimeResponse = (strData) =>
  moment(strData).format('YYYY-MM-DD HH:mm')

export const currencyToDouble = (currency) => {
  let currencyFormat = currency.replace(/\./g, '')
  currencyFormat = currencyFormat.replace('R$ ', '')
  currencyFormat = currencyFormat.replace(',', '.')
  return parseFloat(currencyFormat)
}

export const zeroPad = (str, max) =>
  Array(max - Math.floor(Math.log10(str))).join('0') + str

export const formatDouble = (double) => numberToReal(double)

export const formatCPF = (cpf) => {
  const cpfFormat = cpf.replace(/[^\d]/g, '')

  return cpfFormat.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
}

export default (params) => params
