/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React from 'react'
import propTypes from 'prop-types'
import MaterialTable from 'material-table'
import CustomPagination from './CustomPagination'

export const Table = ({
  columns,
  data,
  actions,
  title,
  search,
  onChangePage,
  totalItems,
  page,
  paginationNumber
}) => {
  return (
    <MaterialTable
      title={title}
      columns={columns}
      data={data}
      style={{
        background: '#fff',
        boxShadow: 'none',
        border: '1px solid #BEC4CC',
        borderRadius: '8px',
        marginTop: '16px'
      }}
      actions={actions}
      options={{
        search,
        toolbar: false,
        actionsColumnIndex: -1,
        paginationType: 'stepped',
        pageSize: 10,
        pageSizeOptions: [10],
        headerStyle: {
          marginLeft: '0',
          color: '#9FA2B4',
          fontWeight: 'bold',
          fontSize: 16,
          height: 75,
          paddingTop: 24,
          paddingLeft: 32,
          paddingBottom: 27,
          borderBottom: '1px solid #DFE0EB',
          zIndex: 0,
          borderRadius: '8px'
        },
        cellStyle: {
          height: 92,
          paddingLeft: 32,
          fontSize: 18,
          fontWeight: 500,
          borderRadius: '8px'
        }
      }}
      localization={{
        body: {
          emptyDataSourceMessage: <h1>Nenhum registro encontrado</h1>
        },
        header: {
          actions: ''
        },
        toolbar: {
          searchTooltip: 'Buscar',
          searchPlaceholder: 'Buscar'
        },
        pagination: {
          labelRowsSelect: 'Registros',
          labelDisplayedRows: '{from}-{to} de {count}',
          firstTooltip: 'Primeira página',
          previousTooltip: 'Anterior',
          nextTooltip: 'Próxima',
          lastTooltip: 'Última página'
        }
      }}
      components={{
        Pagination: () => {
          if (data.length > 0)
            return (
              <CustomPagination
                handleChangePage={onChangePage}
                totalItems={totalItems}
                page={page}
                paginationNumber={paginationNumber}
              />
            )
          else return <td />
        }
      }}
    />
  )
}

Table.propTypes = {
  columns: propTypes.arrayOf(
    propTypes.shape({ title: propTypes.string, field: propTypes.string })
  ).isRequired,
  data: propTypes.arrayOf(propTypes.shape({})),
  title: propTypes.string,
  search: propTypes.bool,
  totalItems: propTypes.number,
  onChangePage: propTypes.func.isRequired,
  page: propTypes.number,
  paginationNumber: propTypes.number.isRequired
}

Table.defaultProps = {
  data: [],
  title: '',
  search: false,
  totalItems: 1,
  page: 1
}
