export const GET_ALL_LANDING_PAGE_DATA_FAILURE =
  'GET_ALL_LANDING_PAGE_DATA_FAILURE'
export const GET_ALL_LANDING_PAGE_DATA_REQUEST =
  'GET_ALL_LANDING_PAGE_DATA_REQUEST'
export const GET_ALL_LANDING_PAGE_DATA_SUCCESS =
  'GET_ALL_LANDING_PAGE_DATA_SUCCESS'
export const GET_LANDING_PAGE_BY_ID_FAILURE = 'GET_LANDING_PAGE_BY_ID_FAILURE'
export const GET_LANDING_PAGE_BY_ID_REQUEST = 'GET_LANDING_PAGE_BY_ID_REQUEST'
export const GET_LANDING_PAGE_BY_ID_SUCCESS = 'GET_LANDING_PAGE_BY_ID_SUCCESS'
export const CREATE_LANDING_PAGE_DATA_FAILURE =
  'CREATE_LANDING_PAGE_DATA_FAILURE'
export const CREATE_LANDING_PAGE_DATA_REQUEST =
  'CREATE_LANDING_PAGE_DATA_REQUEST'
export const CREATE_LANDING_PAGE_DATA_SUCCESS =
  'CREATE_LANDING_PAGE_DATA_SUCCESS'
export const UPDATE_LANDING_PAGE_DATA_FAILURE =
  'UPDATE_LANDING_PAGE_DATA_FAILURE'
export const UPDATE_LANDING_PAGE_DATA_REQUEST =
  'UPDATE_LANDING_PAGE_DATA_REQUEST'
export const UPDATE_LANDING_PAGE_DATA_SUCCESS =
  'UPDATE_LANDING_PAGE_DATA_SUCCESS'
