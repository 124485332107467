import React from 'react'
import { Image, Typography, Button } from 'components/common'
import HeaderLogo from 'assets/images/header-logo.svg'
import BackArrow from 'assets/icons/back-arrow.svg'
import { getUserName, logout } from 'services/auth'

import * as S from './styled'

export const Header = () => {
  const handleExit = () => logout()
  return (
    <S.Header>
      <S.HeaderInfo>
        <Typography tag="h2" variant="secondary" spacing="min">
          Bem vindo ao ambiente de edição de Temas da ConectCar
        </Typography>
        <Typography tag="text">
          Você está editando os temas criados pelo time Conectcar em um ambiente
          totalmente seguro.
        </Typography>
      </S.HeaderInfo>
      <S.HeaderMenu>
        <Image src={HeaderLogo} alt="header-logo" width="60px" height="60px" />
        <nav role="navigation" className="primary-navigation">
          <ul>
            <li>
              <a href="#">
                <Typography tag="h4" variant="secondary">
                  Olá, <strong>{getUserName()}</strong>
                </Typography>
              </a>
              <ul className="dropdown">
                <S.MenuContainer>
                  <Button
                    id="btn_forgot_password"
                    variant="link-primary"
                    onClick={handleExit}
                  >
                    Sair
                  </Button>
                </S.MenuContainer>
              </ul>
            </li>
          </ul>
        </nav>
        <Image src={BackArrow} alt="back-arrow" style={{ marginLeft: 8 }} />
      </S.HeaderMenu>
    </S.Header>
  )
}
