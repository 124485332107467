import * as Yup from 'yup'
import ValidationRules from '../validations/validationsSchemas'

export const offerItemPJSchema = Yup.object().shape({
  // planOffers: ValidationRules.Required(),
  // planPriceOffers: ValidationRules.Required(),
  tagOffers: ValidationRules.Required(),
  supportTextOfferAdd: ValidationRules.Required(),
  textOffers: ValidationRules.Required()
})
