import styled, { css } from 'styled-components'

const wrapperModifiers = {
  success: () => css`
    background-color: #d9edec;
    color: #0a5860;

    .info {
      color: #0a5860;
    }

    .close {
      color: #0a5860;
    }
  `,
  info: () => css`
    background-color: #fbf2e5;
    color: #424242;

    .info {
      color: #fac11e;
    }

    .close {
      color: #424242;
    }
  `,
  error: () => css`
    background-color: #f06466;
    color: #ffffff;

    .info {
      color: #ffffff;
    }

    .close {
      color: #ffffff;
    }
  `
}

export const Wrapper = styled.div`
  ${({ theme, variant, spacing }) => css`
    align-content: flex-start;
    align-items: flex-start;
    border-radius: ${theme.radius.md};
    display: flex;
    margin-bottom: ${spacing ? theme.spacing[spacing] : 0};
    padding: 0.8rem 4.8rem;
    position: relative;
    text-align: left;

    ${!!variant && wrapperModifiers[variant](theme)};

    .icon {
      font-size: 15px;
      position: absolute;
      top: 7px;

      &.info {
        left: 16px;
      }

      &.close {
        cursor: pointer;
        right: 16px;
      }
    }
  `}
`

export const Text = styled.p`
  ${() => css`
    color: inherit;
    font-size: 12px;
  `}
`
