import {
  GET_MONTHLY_FEE_FAILURE,
  GET_MONTHLY_FEE_REQUEST,
  GET_MONTHLY_FEE_SUCCESS
} from 'redux/constants/Lists/MonthlyFee'
import { getMonthlyFee } from 'config/api/Lists'

// GET ALL
const monthlyFeeRequest = () => ({
  type: GET_MONTHLY_FEE_REQUEST
})

const monthlyFeeSuccess = (response) => ({
  type: GET_MONTHLY_FEE_SUCCESS,
  payload: response
})
const monthlyFeeFailure = (payload) => ({
  type: GET_MONTHLY_FEE_FAILURE,
  payload
})

const getAllMonthlyFee = (dispatch) => {
  dispatch(monthlyFeeRequest())
  getMonthlyFee()
    .then((response) => {
      dispatch(monthlyFeeSuccess(response.data))
    })
    .catch((error) => {
      dispatch(monthlyFeeFailure(error.response))
    })
}

export const actionGetMonthlyFee = () => (dispatch) =>
  getAllMonthlyFee(dispatch)
