import styled, { css } from 'styled-components'

const variants = {
  primary: () => css``,

  selected: () => css`
    border-bottom: 5px solid ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.black};
  `
}

export const Checkbox = styled.div`
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  align-items: center;
  border-bottom: 5px solid ${({ theme }) => theme.colors.text.tertiary};
  color: ${({ theme }) => theme.colors.text.tertiary};
  cursor: pointer;
  display: flex;
  font-size: ${({ theme }) => theme.fontSize.lg};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: ${({ theme }) => theme.spacing.lgl};
  padding-bottom: ${({ theme }) => theme.spacing.xs};
  ${(props) => variants[props.variant]}
  user-select: none;

  span {
    margin-left: ${({ theme }) => theme.spacing.smx};
  }
`

export const WrapperCheckboxGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${({ theme, spacing }) =>
    spacing === '' ? 0 : `${theme.spacing[spacing]}`};

  ${Checkbox}:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing.lgl};
  }
`
export const Radio = styled.div`
  align-items: center;
  background-color: ${({ selected }) => (selected ? '#FF6338' : '#ffffff')};
  border: ${({ selected }) => (selected ? 'none' : '1px solid #bec4cc')};
  border-radius: 50px;
  display: flex;
  height: 18px;
  justify-content: center;
  width: 18px;
`
