import styled from 'styled-components'

export const WrapperMenu = styled.nav`
  box-shadow: ${({ theme }) => theme.shadow.menu};
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.mdl};
  width: 256px;
  z-index: ${({ theme }) => theme.zIndex.base};
`

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  height: 80vh;
  position: relative;
  button {
    font-size: 18px;
  }
`

export const MenuTitle = styled.span`
  border-bottom: 1px solid #f2f2f2;
  color: ${({ theme }) => theme.colors.primary.base};
  font-size: ${({ theme }) => theme.fontSize.lg};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: ${({ theme }) => theme.spacing.mdx};
  margin-top: ${({ theme }) => theme.spacing.lgl};
  padding-bottom: ${({ theme }) => theme.spacing.base};
`

export const ItemMenu = styled.div`
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  align-items: center;
  align-items: center;
  border-left: 3px solid ${({ theme }) => theme.colors.tertiary.base};
  color: ${({ theme }) => theme.colors.tertiary.dark};
  cursor: pointer;
  display: flex;
  font-size: ${({ theme }) => theme.fontSize.md};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  height: 40px;
  padding-left: ${({ theme }) => theme.spacing.sm};
  user-select: none;
  width: 100%;
`

export const WrapperAddIcon = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary.base};
  border-radius: 50%;
  display: flex;
  height: 24px;
  justify-content: center;
  margin-left: ${({ theme }) => theme.spacing.sm};
  width: 24px;
`

export const BoxAction = styled.div`
  bottom: 150px;
  display: flex;
  position: absolute;
`
