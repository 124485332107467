import { combineReducers } from 'redux'

import dialogs from './Dialogs'
import wrapper from './Wrapper'
import auth from './Auth'
import registration from './Registration'
import landingPage from './LandingPage'
import lists from './Lists'

export default () =>
  combineReducers({
    wrapper,
    dialogs,
    auth,
    registration,
    landingPage,
    lists
  })
